import styles from "./index.module.scss"
import tili from "./tili.png"
import {DCard} from "../../../../components/DCard";
import {useEffect, useState} from "react";
import {DBtn} from "../../../../components/DBtn";
import copy from "copy-to-clipboard";
import {message} from "antd";
import {GetInviteList, InviteListItem} from "../../../../api/GetInviteList";
import {getLoginUserInfo, getYearMonthDayTimeStampNew, TgLink} from "../../../../utils/utils";
import DEmpty from "../../../../components/DEmpty";
import classnames from "classnames";
import {GetCanClaimEnergy, GetCanClaimEnergyResponse} from "../../../../api/GetCanClaimEnergy";
import {ClaimInviteEnergy} from "../../../../api/ClaimInviteEnergy";

const shareLinkPre = "Instantly! Immediately!! Forthwith!!! Join Us And Earn momo!!! "

export function InviteComp({confirm}: {confirm:() => void}) {

  const [link, setLink] = useState()
  const [list, setList] = useState<InviteListItem[]>([])
  const [claimData, setClaimData] = useState<GetCanClaimEnergyResponse>(new GetCanClaimEnergyResponse())

  useEffect(() => {
    setLink(getLoginUserInfo().shareUrl)
    setList([])
    getData().then()
    getClaimData().then()
  }, [])

  async function getData() {
    const docs = await GetInviteList()
    setList([...docs])
  }
  async function getClaimData() {
    const data = await GetCanClaimEnergy()
    setClaimData(data)
  }


  async function handleCopy() {
    const ret = copy(shareLinkPre + link)
    if (ret) {
      await message.success('Copy Link: Successful')
      return
    }
    message.error('Copy Link: Failed')
  }

  function handleShare() {
    const shareLink = `https://t.me/share/url?url=${encodeURIComponent(shareLinkPre) + link}`
    TgLink(shareLink, 'tg')
  }
  async function handleClaim() {
    const err = await ClaimInviteEnergy()
    if (err) return
    getClaimData().then()
    confirm()
  }

  const getContext = <div>
    <div className={styles.invite_card_title}>Exclusive invitation link</div>
    <div className={styles.invite_card_card}>{link}</div>
    <div className={styles.invite_btn_wrap}>
      <div><DBtn
        title='Copy'
        onclick={handleCopy}
        titleStyle={{
          textShadow: "-1px -1px 0 #0F7481, 1px -1px 0 #0F7481, -1px 1px 0 #0F7481, 1px 1px 0 #0F7481, 0px 2px 0px #0F6671",
        }}
        style={{
          width: '148px',
          height: '44px',
          background: "linear-gradient( 180deg, #38ABFF 0%, rgba(19,222,243,0.82) 100%)",
          boxShadow: "0px 6px 4px 0px rgba(84,102,109,0.67), 0px 3px 0px 0px #0F6671, inset 0px 0px 12px 0px #50FFF9"
        }} /></div>
      <div><DBtn title='Share' style={{width: '148px', height: '44px'}} onclick={handleShare} /></div>
    </div>
  </div>

  const getDrawContext = <div>
    <div className={styles.draw_card_item}>
      {/*待领取邀请奖励*/}
      <div>To be collected: {claimData.unclaimedEnergy}</div>
      <img src={tili} alt=""/>
    </div>
    <div className={classnames([styles.draw_card_item, styles.draw_card_item1])}>
      {/*今日可领取邀请奖励*/}
      <div>Available for collection today: {claimData.canClaimedEnergy}</div>
      <img src={tili} alt=""/>
    </div>
    <div className={styles.receive_wrap}>
      {
        claimData.canClaimedEnergy <= 0 ? <div className={styles.refresh_tips}>
          UTC+0 refresh
        </div> : null
      }
      <DBtn title={claimData.canClaimedEnergy > 0 ? 'Receive today' : 'Received'} disabled={claimData.canClaimedEnergy <= 0} onclick={handleClaim} style={{minWidth: '148px', height: '36px'}} />
    </div>
  </div>

  return <div className={styles.invite_wrap}>
    <div className={styles.invite_top}>
      <div className={styles.invite_top_item}>
        <div className={styles.invite_top_item_dot}></div>
        <div className={styles.invite_top_item_text}>Invite 1 friend and you can get 2 energy</div>
      </div>
      <div className={styles.invite_top_item}>
        <div className={styles.invite_top_item_dot}></div>
        <div className={styles.invite_top_item_text}>Up to 20 Energy per day can be obtained by invitation</div>
      </div>
    </div>
    <div className={styles.invite_center}>
      <DCard context={getContext} />
    </div>
    <div className={styles.invite_center1}>
      <DCard context={getDrawContext} />
    </div>
    <div className={styles.invite_title}>Invitation record</div>
    <div className={styles.invite_bot}>
      {
        list.map(it => {
          return <div className={styles.invite_bot_item} key={it.Time}>
            <div className={styles.invite_bot_item_l}>{it.Name}</div>
            <div className={styles.invite_bot_item_c}>{getYearMonthDayTimeStampNew(it.Time * 1000)}</div>
            <div className={styles.invite_bot_item_r}>
              <img src={tili} alt=""/>
              +{it.Energy}
            </div>
          </div>
        })
      }
      {
        !list || list.length <= 0 ? <DEmpty></DEmpty> : null
      }
    </div>
  </div>
}