import { Send } from "./api";

export enum ClaimRedEnvelopeRes{
    ClaimRedEnvelopeSuccess   = 0,
    ClaimRedEnvelopeClaimed   = 1,
    ClaimRedEnvelopeExpire    = 2,
    ClaimRedEnvelopeNotEnough = 3,
}

// 请求类
class ClaimRedEnvelopeRequest {
    rid?: string; // 红包ID
}

// 响应类
class ClaimRedEnvelopeResponse {
    code?: ClaimRedEnvelopeRes;
    errMsg?: string;
    claimAmount?: string;
    symbol?: string;
}

// 领取红包
export const APIClaimRedEnvelope = async (rid: string): Promise<[ClaimRedEnvelopeResponse, Error | null]> => {
    const request = new ClaimRedEnvelopeRequest();
    request.rid = rid;

    const [ret, err] = await Send("/API/ClaimRedEnvelope", request, ClaimRedEnvelopeResponse);

    if (err) {
        return [{}, err];
    }

    return [ret, null];
}
