import styles from './styles.module.scss'
import {DBtn} from "../../../../components/DBtn"

interface CardProps {
  click?: () => void
}

const Card = (props: CardProps) => {
  const {click} = props
  return (
    <div className={styles.unlock_card}>
      <div className={styles.text}>The current capacity pool cannot be unlocked by skipping levels.</div>
      <div className={styles.btn}>
        <DBtn title={'I got it'} onclick={click} />
      </div>
    </div>
  )
}


const UnlockFail = ({confirm}: {confirm:() => void}) => {

  function handleClick() {
    confirm()
  }

  return (
    <div className={styles.unlock}>
      <div className={styles.item}>
        <Card click={handleClick}/>
      </div>
    </div>
  )
}

export default UnlockFail