import {Send} from "./api";

class GetCanClaimEnergyRequest {}
export class GetCanClaimEnergyResponse {
  unclaimedEnergy:number = 0 // 总待领的数量
  canClaimedEnergy:number = 0 // 今日剩余可领数量
  perDayMaxEnergy:number = 0 // 每日最大可领数量
  errMsg: string = ''
}

export async function GetCanClaimEnergy() {
  const [ret, err] = await Send("/API/GetCanClaimEnergy", new GetCanClaimEnergyRequest(), GetCanClaimEnergyResponse)
  if (err || ret.errMsg.length > 0) {
    return new GetCanClaimEnergyResponse()
  }
  return ret
}