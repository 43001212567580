import { Send } from "./api";
import {ClassArray} from "../3rdparty/ts-json";
import {GetUserInfo, GetUserInfoItem} from "./GetUserInfo";
import {getYearMonthDayTimeStampNew} from "../utils/utils";
import {buildUserName} from "../utils/telegramUtil";
import {RedEnvelopeTransactionItem} from "./GetRedEnvelopeTransactions";

// 请求类
class GetRedEnvelopeClaimLogRequest {
    rId?: string; // 红包ID
}

// 响应的单项记录类
export class GetRedEnvelopeClaimLogItem {
    userId?: string;
    claimed?: string;
    time?: number;
}

// 响应类
class GetRedEnvelopeClaimLogResponse {
    errMsg?: string;
    items?: ClassArray<GetRedEnvelopeClaimLogItem> = new ClassArray<GetRedEnvelopeClaimLogItem>(GetRedEnvelopeClaimLogItem);
}

export class RedEnvelopeClaimLogItem{
    username:string = ""
}

// 获取红包领取记录
export const APIGetRedEnvelopeClaimLog = async (rId: string): Promise<[RedEnvelopeClaimLogItem[], Error | null]> => {
    const request = new GetRedEnvelopeClaimLogRequest();
    request.rId = rId;

    const [ret, err] = await Send("/API/GetRedEnvelopeClaimLog", request, GetRedEnvelopeClaimLogResponse);

    if (err) {
        return [[], err];
    }

    if (ret.errMsg) {
        return [[], new Error(ret.errMsg)];
    }

    if(ret.items && ret.items.length > 0){
        const uids = ret.items.map(item=>item.userId!);
        const userInfos = await GetUserInfo(uids);
        const userMap = userInfos.reduce((map:{
            [key: string]: GetUserInfoItem;
        }, user) => {
            map[user.Uid] = user;
            return map;
        }, {});

        const list = ret.items.sort((a, b)=>(b.time ?? 0) - (a.time ?? 0));
        const resList = list.map(item=>{
            const redEnvelopeClaimLogItem = new RedEnvelopeClaimLogItem();
            redEnvelopeClaimLogItem.username = buildUserName(userMap[item.userId ?? ''])
            return redEnvelopeClaimLogItem;
        })
        return [resList, null]
    }

    return [[], null];
}
