import {ClassArray} from "../3rdparty/ts-json";
import {Send} from "./api";

class GetTask20ListRequest {}
class GetTask20ListResponse {
  errMsg:string = ""
  items: ClassArray<Task20Document> = new ClassArray<Task20Document>(Task20Document)
}

export class Task20Document {
  Id:number = 0
  Max:number = 0
  Condition:string = "" // eg: score:5000,energy:20
  disabled:boolean = false
  Status:number = 0 // 状态：0 是不开放  1开放
}

// 获取提取列表可选的数据
export async function GetTask20List() {
  const request = new GetTask20ListRequest()
  const [ret, err] = await Send("/API/GetTask20List", request, GetTask20ListResponse)
  if (err || ret.errMsg.length > 0) { return [] }
  return ret.items
}