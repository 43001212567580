import {Send} from "./api";
import {message} from "antd";

class ClaimRequest {
  taskID:number = 0
  oldStartTime:number = 0
  newStartTime:number = 0
}

class ClaimResponse {
  errMsg:string = ""
}

// 对池子中的积分收取
export async function Claim(taskID:number, oldStartTime:number, newStartTime:number):Promise<null | string> {
  if (oldStartTime >= newStartTime) return 'oldTime is invalid'
  const request = new ClaimRequest()
  request.taskID = taskID
  request.oldStartTime = oldStartTime
  request.newStartTime = newStartTime
  const [ret, err] = await Send("/API/Claim", request, ClaimResponse)
  if (err || ret.errMsg.length > 0) {
    const t = err?.message || ret.errMsg
    if (!t.includes("oldTime is invalid")) {
      message.warning(err?.message || ret.errMsg)
    }
    return err?.message || ret.errMsg
  }
  return null
}