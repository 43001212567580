// export const WSSUrl = "https://xinkpro.app/iapi"; // http请求
export const WSSUrl = "wss://xinkpro.app/ws/"; // ws协议
export const Env:"pro"|"dev"|"beta"|"alpha"  = "dev";
export const ContractAddress = '0x25d887ce7a35172c62febfd67a1856f20faebb00'
export const TwitterLink = "https://x.com/soex_io" // 任务 twitter链接
export const TGGroupLink = "https://t.me/+Dj02mWfJHA83YTk1" // 任务  TG加群链接
export const WebAppUrl = "https://t.me/imomo_miner_bot/app" // 本小程序地址
export const ManifestUrl = "https://imomo-image.oss-ap-southeast-1.aliyuncs.com/real/manifest.json" // Ton钱包需要的manifest地址

export const TGAppUrl = "https://t.me/imomo_miner_bot/app";
export const TGBotUrl = "https://t.me/imomo_miner_bot?start=";

export const scaleNum = 100


export const ChainList = [
  {
    chainId: '0x38',
    chainName: 'BSC', // BNB Smart Chain Mainnet
    blockExplorerUrls: 'https://testnet.bscscan.com/'
  },
  // {
  //   chainId: '0x1',
  //   chainName: 'ETH',
  //   blockExplorerUrls: 'https://etherscan.io/'
  // }
]
