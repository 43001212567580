import styles from './styles.module.scss'
import integralIcon from '../../../../assets/images/home/integral-icon.png'
import tgIcon from '../../../../assets/images/home/tg-icon.png'
import xIcon from '../../../../assets/images/home/x-icon.png'
import claimIcon from '../../../../assets/images/home/claim_icon.png'
import {DBtn} from "../../../../components/DBtn"
import {useEffect, useRef, useState} from "react";
import {CheckFollowTwitter} from "../../../../api/CheckFollowTwitter";
import {CheckJoinTGGroup} from "../../../../api/CheckJoinTGGroup";
import {
  addTaskToLocal,
  getLocalTaskList,
  getLoginUserInfo,
  isMobile,
  removeLocalTask,
  TgLink
} from "../../../../utils/utils";
import selectIcon from "./selectIcon.png"
import {CheckFollowPepeBotTwitter} from "../../../../api/CheckFollowPepeBotTwitter";
import {ClaimDailyEnergyReward, ClaimResult} from "../../../../api/ClaimDailyEnergyReward";
import {message} from "antd";
import {CheckFollowSOEXTwitter, CheckJoinTGSoexCommunity} from "../../../../api/CheckFollowSOEXTwitter";
import FlipMove from "react-flip-move";
import classNames from 'classnames'
import {Async} from "../../../../utils/Async";

interface CardProps {
  data: TaskItem
  onclick?: (type:ActionType, isSuccess:boolean) => void
}

const Card = (props: CardProps) => {
  const {data, onclick} = props

  const buildBtn = () =>{
    if(data.always){
      const info = getLoginUserInfo();
      if(info.theEarlyMinerCheckInIsOpen){
        const claimed = parseInt(data.always.split("/")[0]) >= parseInt(data.always.split("/")[1])
        console.log(data.disable, claimed, data.always, "soexsoex")
        if(data.disable as boolean || claimed){
          return  <DBtn
              titleStyle={{
                color:"#FFFFFF",
                textShadow:"0px 3px 0px #797979, inset 0px 0px 12px #FFFFFF !important",
                textStroke: "1px #6C6C6C",
              }}
              style={{
                width:"112px",
                height:"36px",
                background:"linear-gradient( 180deg, #E5E5E5 0%, rgba(159,159,159,0.82) 100%)",
                boxShadow:"0px 6px 4px 0px #82C5E2, 0px 3px 0px 0px #797979, inset 0px 0px 12px 0px #FFFFFF",
                marginTop:"0px"
              }} title={'Claimed'} disabled={true} onclick={() => onclick?.(data.type!, data.disable as boolean)}/>
        }
        return  <DBtn style={{
          width:"112px",
          height:"36px",
          marginTop:"0px"
        }} title={'Claim'} onclick={() => onclick?.(data.type!, data.disable as boolean)}/>
      }
      return <DBtn
          titleStyle={{
            color:"#FFFFFF",
            textShadow:"0px 3px 0px #797979, inset 0px 0px 12px #FFFFFF !important",
            textStroke: "1px #6C6C6C",
          }}
          style={{
            width:"112px",
            height:"36px",
            background:"linear-gradient( 180deg, #E5E5E5 0%, rgba(159,159,159,0.82) 100%)",
            boxShadow:"0px 6px 4px 0px #82C5E2, 0px 3px 0px 0px #797979, inset 0px 0px 12px 0px #FFFFFF",
            marginTop:"0px"
          }} title={'Claimed'} disabled={true}/>
    }
    return  <DBtn style={{
      width:"112px",
      height:"36px",
      marginTop:"0px"
    }} title={'GO'} onclick={() => onclick?.(data.type!, data.disable as boolean)}/>
  }

  return (
      <div className={styles.task_card}>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src={data.icon} alt="tgIcon"/>
            {data?.title}
          </div>
          <div className={styles.text}>
            <div>
              {data.text}
            </div>
          </div>
          {data.disable && <img className={styles.tag} src={selectIcon}></img>}
        </div>
        <div className={styles.flex_content}>
          <div className={styles.num}>
            <img src={integralIcon} alt="icon"/>
            x
            <span>{data.num}</span>
          </div>
          <div className={styles.btn}>
            {
              buildBtn()
            }
          </div>
        </div>
      </div>
  )
}

class TaskItem {
  title?:string
  text?:string
  num?:string
  icon?:string
  type?:ActionType
  disable?:boolean
  always?:string
}

export enum ActionType {
  tg = "tg",
  twitter = "twitter",
  pepe_bot = "pepe_bot",
  claim = "claim",
  followSoexTwitter = "followSoexTwitter",
  joinSoexTg = "joinSoexTg"
}

const Tasks = ({condition, confirm, claimStat}: { condition: string[], confirm: () => void, claimStat:string}) => {
  const info = getLoginUserInfo()
  const [list, setList] = useState<TaskItem[]>([])
  const listenerFn = useRef<any>()
  const leaveMethod = useRef<string>('')

  useEffect(() => {
    let taskList = [
      {
        title: 'The Early Miner',
        text: `Limited Energy`,
        num: info.theEarlyMinerCheckIn?.toString(),
        icon: claimIcon,
        type: ActionType.claim,
        disable: condition.includes('TheEarlyMinerCheckIn'),
        always:claimStat
      },
      // {
      //   title: 'Follow imomo.ai on Twitter',
      //   text: `Follow imomo.ai on Twitter`,
      //   num: info.followSoexTwitter?.toString(),
      //   icon: xIcon,
      //   type: ActionType.followSoexTwitter,
      //   disable: condition.includes("FollowSOEXTwitter")
      // },
      // {
      //   title: 'Join imomo.ai TG Community',
      //   text: `Join imomo.ai TG Group will grant access to:`,
      //   num: info.joinTGSoexCommunity?.toString(),
      //   icon: tgIcon,
      //   type: ActionType.joinSoexTg,
      //   disable: condition.includes('JoinTGSoexCommunity')
      // },
      {
        title: 'Follow imomo.ai on Twitter',
        text: `Follow imomo.ai on Twitter will grant access to:`,
        num: info.followPepeBot?.toString(),
        icon: xIcon,
        type: ActionType.pepe_bot,
        disable: condition.includes('FollowPepeBotTwitter')
      },
      {
        title: 'Join imomo.ai TG Channel',
        text: `Join imomo.ai TG Channel will grant access to:`,
        num: info.joinTGGroup?.toString(),
        icon: tgIcon,
        type: ActionType.tg,
        disable: condition.includes('JoinTGGroup')
      },
    ]
    taskList = [...taskList.filter((item=>!item.disable)), ...taskList.filter((item=>item.disable))];
    setList(taskList)
  }, [condition, claimStat])

  useEffect(() => {
    return () => {
      listenerFn.current && document.removeEventListener("visibilitychange", listenerFn.current);
    }
  }, [])

  function listenerJoinTg() {
    const handleVisibilityChange = () => {
      if (leaveMethod.current !== 'click') return
      if (!document.hidden) {
        confirm()
        leaveMethod.current = ''
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return handleVisibilityChange
  }

  useEffect(()=>{
    Async(async ()=>{
      const checkTaskList = getLocalTaskList();
      if(checkTaskList.includes(ActionType.joinSoexTg)){
        await CheckJoinTGSoexCommunity();
        confirm();
        removeLocalTask(ActionType.joinSoexTg)
      }
      if(checkTaskList.includes(ActionType.tg)){
        await CheckJoinTGGroup()
        confirm();
        removeLocalTask(ActionType.tg)
      }
    })
  }, [])

  async function handleClick(type:ActionType, isSuccess:boolean) {
    const info = getLoginUserInfo()
    if (type === ActionType.tg) {
      if (!isSuccess) {
        addTaskToLocal(ActionType.tg)
        await CheckJoinTGGroup()
        confirm()
      }
      TgLink(info.joinTgGroupLink, 'tg');
      listenerFn.current = listenerJoinTg()
      leaveMethod.current = 'click'
      return
    }
    if (type === ActionType.twitter) {
      TgLink(info.followTwitterLink, 'web')
      if (!isSuccess) { await CheckFollowTwitter() }
      confirm()
      return
    }
    if (type === ActionType.pepe_bot) {
      TgLink(info.followPepeBotTwitterLink, 'web')
      if (!isSuccess) { await CheckFollowPepeBotTwitter() }
      confirm()
      return
    }
    if (type === ActionType.claim){
      if(!isSuccess){
       const [res, err] = await ClaimDailyEnergyReward();
       confirm();
       if(err) return message.error(err.message);
       if(res.result  === ClaimResult.ResultOK) return message.success("Successfully claimed!")
       if(res.result  === ClaimResult.ResultClaimed) return message.info("You have already received it today!")
       if(res.result  === ClaimResult.ResultNoMoreQuota) return message.info("You're late, come early tomorrow!")
      }
      return ;
    }

    if (type === ActionType.followSoexTwitter){
      let link = "https://x.com/soex_io"
      if (!isMobile()) {
        link = info.followSoexTwitterLink
      }
      TgLink(link, 'web')
      if(!isSuccess){
        await CheckFollowSOEXTwitter();
        confirm()
      }
      return ;
    }

    if (type === ActionType.joinSoexTg){
      if(!isSuccess){
        addTaskToLocal(ActionType.joinSoexTg)
        await CheckJoinTGSoexCommunity();
        confirm();
      }
      TgLink(info.joinTGSoexCommunityLink, 'tg')
      return ;
    }
  }

  return (
    <div className={styles.task}>
      <FlipMove>
        {
          list.map((item, index) => (
              <div 
                className={classNames([styles.item])} 
                id={'taskItem' + index} 
                key={item.title}
              >
                <Card data={item} onclick={handleClick} />
              </div>
          ))
        }
      </FlipMove>
    </div>
  )
}

export default Tasks
