import {NcEvent} from "../3rdparty/ts-nc";

export class ClaimSuccess extends NcEvent<string> {
  static sym = Symbol();
}

export class TimeChangedEvent extends  NcEvent<string> {
  static sym = Symbol();
}

export let Login401Fn: any;

export function SetLogin401Fn(fn: () => void) {
  Login401Fn = fn
}