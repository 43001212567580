import styles from './index.module.scss';
import './index.scss';

import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createPortal, } from 'react-dom';
import CloseIcon from '../../../../assets/images/home/lucky_close.png';
// import Coin1Icon from '../../../../assets/images/home/lucky_coin1.png';
// import Coin2Icon from '../../../../assets/images/home/lucky_coin2.png';
// import Coin3Icon from '../../../../assets/images/home/lucky_coin3.png';
// import Coin4Icon from '../../../../assets/images/home/lucky_coin4.png';
// import Coin5Icon from '../../../../assets/images/home/lucky_coin5.png';

import Coin1Icon from '../../../../assets/images/home/imomo/imomo-coin.png';
import Coin2Icon from '../../../../assets/images/home/imomo/imomo-coin.png';
import Coin3Icon from '../../../../assets/images/home/imomo/imomo-coin.png';
import Coin4Icon from '../../../../assets/images/home/imomo/imomo-coin.png';
import Coin5Icon from '../../../../assets/images/home/imomo/imomo-coin.png';

import {ClaimRedEnvelopeRes} from "../../../../api/ClaimRedEnvelope";
import {useNavigate} from "react-router-dom";

type LuckCoinContentProps = {
    sender: string
    value?: string,
    coin: string,
    isOpen?: boolean
    isExpire?: boolean
    onClose: () => void
    onOpen?: () => Promise<void>
}

export enum LuckCoinStatus {
    Init = 'init',
    Expire = 'expire',
    Claimed = 'claimed', // 领取过了
    Opened = 'opened',
    NotEnough = "notEnough" // 领完了
}

export type LuckCoinContentRef = {
    animateOpen: (value: string, type:LuckCoinStatus) => void,
    openEnvelope: (value: string, type:LuckCoinStatus) => void,
}

const LuckCoinContent = forwardRef((props: LuckCoinContentProps, ref: ForwardedRef<LuckCoinContentRef>) => {
    const [value, setValue] = useState(props.value || '');
    const navigate = useNavigate();
    const [state, setState] = useState(() => {
        if (props.isExpire) {
            return LuckCoinStatus.Expire
        }
        if (props.isOpen) {
            return LuckCoinStatus.Opened
        }
        return LuckCoinStatus.Init
    });

    const onOpen = () => {
        if (props.onOpen) {
             props.onOpen().then();
        }

        const ele = animateRef.current!;
        if (ele) {
            ele.style.animation = `lucky_shake 0.3s ease infinite`;
        }
    }

    const animateRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => {
        return {
            animateOpen: (value: string, type:LuckCoinStatus) => {
                setValue(value);
                const ele = animateRef.current!;
                if (ele) {
                    ele.style.animation = `lucky_rotate 1s ease`
                    setTimeout(() => {
                        setState(type)
                        navigate("/")
                    }, 500);
                }
            },
          openEnvelope: (value: string, type:LuckCoinStatus) => {
            setValue(value);
            setState(type)
            navigate("/")
          }
        }
    }, [])

    const getCoinAnimation = (coinNum = 10) => {
    if(coinNum > 7) coinNum = 7
    const startPos = document.getElementById(`red-coin-value`);
    const startPosRect = startPos!.getBoundingClientRect();
    const startX = startPosRect.left + startPosRect.width / 2;
    const startY = startPosRect.top + startPosRect.height / 2;

    const target = document.getElementById('coinBalance');
    const targetRect = target!.getBoundingClientRect();
    const endX = targetRect.left + 0.5;
    const endY = targetRect.top + (targetRect.height - 35) / 2;

    const deltaX = endX - startX;
    const deltaY = endY - startY;
    const animationName = `coinAnimation-${Date.now()}`;
    const keyframes = `
        @keyframes ${animationName} {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }
            100% {
                transform: translate(${deltaX}px, ${deltaY}px);
                opacity: 0.9;
            }
        }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);

    for (let i = 0; i < coinNum; i++) {
      const coin = document.createElement('div');
      coin.className = 'coin_icon';
      document.body.appendChild(coin);
      coin.style.left = `${startX}px`;
      coin.style.top = `${startY}px`;
      coin.style.animation = `${animationName} 1s cubic-bezier(0.25, 1, 0.5, 1) forwards`;
      coin.style.animationDelay = `${i * 0.1}s`; // Delay for a more staggered effect
      coin.style.opacity = "0";
      coin.addEventListener('animationend', () => {
        coin.classList.add('hidden');
        setTimeout(() => {
          document.body.removeChild(coin);
        }, 500);
      });
    }

    setTimeout(() => {
      document.head.removeChild(styleSheet);
    }, 1000 + coinNum * 100);
  };

    const renderInit = () => {
        return (
            <>
                <div className={styles.bg2}></div>
                <div className={styles.send_info}>
                    <div className={styles.sender}>{props.sender}</div>
                    <div className={styles.send_desc}>sent a red envelope</div>
                </div>
                <div className={styles.open} onClick={onOpen}>
                    <div className={styles.open_text}>open</div>
                </div>
            </>
        )
    }

    const renderExpire = () => {
        return (
            <>
                <div className={styles.bg2}></div>
                <div className={styles.send_info}>
                    <div className={styles.sender}>{props.sender}</div>
                    <div className={styles.send_desc}>sent a red envelope</div>
                </div>
                <div className={styles.expire} >
                    <div className={styles.open_text}>expired</div>
                </div>
                <div className={styles.expire_desc}>
                    The red envelope has been collected!
                </div>
            </>
        )
    }

    const renderOpened = () => {
        return (
            <>
                <div className={styles.opened_bg2}></div>
                <div className={styles.send_info}>
                    <div className={styles.sender}>{props.sender}</div>
                    <div className={styles.send_desc}>sent a red envelope</div>
                    <div id={"red-coin-value"} className={styles.send_value} >{value}</div>
                    <div className={styles.send_coin}>{props.coin}</div>
                </div>
            </>
        )
    }

    const renderClaimed = () => {
      return (
        <>
          <div className={styles.bg2}></div>
          <div className={styles.send_info}>
            <div className={styles.sender}>{props.sender}</div>
            <div className={styles.send_desc}>sent a red envelope</div>
          </div>
          <div className={styles.expire} >
            <div className={styles.open_text}>Received</div>
          </div>
          <div className={styles.expire_desc}>
            The red envelope has been collected!
          </div>
        </>
      )
    }

  const renderNotEnough = () => {
    return (
      <>
        <div className={styles.bg2}></div>
        <div className={styles.send_info}>
          <div className={styles.sender}>{props.sender}</div>
          <div className={styles.send_desc}>sent a red envelope</div>
        </div>
        <div className={styles.expire} >
          <div className={styles.open_text}>NotEnough</div>
        </div>
        <div className={styles.expire_desc}>
          The red envelope has been collected!
        </div>
      </>
    )
  }

    return (
        <div className={styles.modal}>
            <div className={styles.bg1}></div>
            <div className={styles.close}>
                <img className={styles.close_icon} src={CloseIcon} alt='close' onClick={()=>props.onClose()} />
            </div>
            <div className={styles.content} ref={animateRef} >
                <img src={Coin1Icon} className={styles.coin1} draggable={false} alt=''></img>
                <img src={Coin2Icon} className={styles.coin2} draggable={false} alt=''></img>
                <img src={Coin3Icon} className={styles.coin3} draggable={false} alt=''></img>
                {state === LuckCoinStatus.Opened
                    ? <img src={Coin5Icon} className={styles.coin5} draggable={false} alt=''></img>
                    : <img src={Coin4Icon} className={styles.coin4} draggable={false} alt=''></img>
                }

                {state === LuckCoinStatus.Init && renderInit()}
                {state === LuckCoinStatus.Expire && renderExpire()}
                {state === LuckCoinStatus.Opened && renderOpened()}
                {state === LuckCoinStatus.Claimed && renderClaimed()}
                {state === LuckCoinStatus.NotEnough && renderNotEnough()}
            </div>
        </div>
    )
})

type LuckCoinModalProps = {
    visible: boolean
}

const LuckCoinModal = forwardRef((props: LuckCoinModalProps & LuckCoinContentProps, ref: ForwardedRef<LuckCoinContentRef>) => {
    const { visible, ...others } = props
    return visible ? createPortal(<LuckCoinContent {...others} ref={ref} />, document.body) : null;
});

export default LuckCoinModal;
