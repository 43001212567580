import {Send} from "./api";
import {getLoginUserInfo, isMobile} from "../utils/utils";
import {ClassArray} from "../3rdparty/ts-json";

class GetConfigRequest {}
class GetConfigResponse {
  errMsg:string = ""
  followTwitter:number = 0
  joinTGGroup: number = 0
  followPepeBot:number = 0
  theEarlyMinerCheckIn:number = 0
  followSoexTwitter:number = 0
  joinTGSoexCommunity:number = 0
  joinTGSoexCommunityLink:string = ''
  followSoexTwitterLink:string = ""
  joinTgGroupLink:string = ''
  followTwitterLink:string = ""
  followPepeBotTwitterLink:string = ""
  mFollowPepeBotTwitterLink:string = ""
  shareUrl:string = ''
  theEarlyMinerCheckInIsOpen:boolean = false
  withdrawLog:ClassArray<WithdrawLog> = new ClassArray<WithdrawLog>(WithdrawLog)
}

export class WithdrawLog{
  id?:string
  isWithdraw?:boolean
}

export async function GetConfig() {
  const request = new GetConfigRequest()
  const [ret, err] = await Send("/API/GetConfig", request, GetConfigResponse)
  if (err || ret.errMsg.length > 0) {
    return
  }
  const info = getLoginUserInfo()
  if (isMobile()) {
    ret.followPepeBotTwitterLink = ret.mFollowPepeBotTwitterLink
  }

  localStorage.setItem("__soex_bot__info__", JSON.stringify({ ...info, ...ret }))
}