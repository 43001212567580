import {Send} from "./api";

class CheckFollowSOEXTwitterRequest{

}

class CheckFollowSOEXTwitterResponse{
    errMsg:string = ""
}

export const CheckFollowSOEXTwitter = async () =>{
    const request = new CheckFollowSOEXTwitterRequest()
    const [ret, err] = await Send("/API/CheckFollowSOEXTwitterTwitter", request, CheckFollowSOEXTwitterResponse)
    if (err || ret.errMsg.length > 0) {
        return err || ret.errMsg
    }
}


class CheckJoinTGSoexCommunityResponse{
    errMsg?:string
    isMember?:boolean
}


export const CheckJoinTGSoexCommunity = async ():Promise<[CheckJoinTGSoexCommunityResponse | null, Error | null]> =>{
    const request = new CheckFollowSOEXTwitterRequest()
    const [ret, err] = await Send("/API/CheckJoinTGSoexCommunity", request, CheckJoinTGSoexCommunityResponse)
    if (err) {
        return [null, err]
    }
    if(ret.errMsg) return [null, new Error(ret.errMsg)]
    return [ret, err]
}
