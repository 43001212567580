import styles from './styles.module.scss'
import integralIcon from '../../../../assets/images/home/integral-icon.png'
import tokenIcon from '../../../../assets/images/home/imomo/imomo-coin.png'
import {DBtn} from "../../../../components/DBtn"
import {PostUnlock} from "../../../../api/Unlock";
import {useRef} from "react";
import {getSeconds, scaledDow2w} from "../../../../utils/utils";
import {runAfter} from "../../../../utils/Async";

interface CardProps {
  data: { energy: number, rate: number, score:number, type:'energy'|'score' }
  click?: () => void
}

const Card = (props: CardProps) => {
  const {data, click} = props
  return (
    <div className={styles.unlock_card}>
      <div className={styles.text}>
        After unlocking, production capacity <br/> {`will increase by +${scaledDow2w(data.rate)}/S`}
      </div>
      <div className={styles.content}>
        <div className={styles.num}>
          <img src={data.type === 'energy' ? integralIcon : tokenIcon} alt="icon"/>
          <span>-{data.type === 'energy' ? data[data.type] : scaledDow2w(data[data.type])}</span>
        </div>
      </div>
      <div className={styles.btn}>
        <DBtn title={'Unlock'} onclick={click}/>
      </div>
    </div>
  )
}


const Unlock = ({rate, energy, confirm, taskId, score, type}: { rate: number, energy: number, score: number, type: 'energy' | 'score', confirm:(str?:string) => void, taskId:number}) => {
  const data = { energy: energy, rate: rate, score, type }
  const loading = useRef(false)
  const isClick = useRef(false)

  async function handleClick() {
    if (!isClick.current) {
      if (loading.current) return
      loading.current = true
      isClick.current = true
      const err = await PostUnlock(taskId, getSeconds(Date.now()))
      setTimeout(() => { isClick.current = false }, 1000)
      if (err) {
        if (err && typeof err === 'string' && err.includes("There are unlocked tasks and the current task cannot be unlocked")) {
          confirm(err)
        }
        return loading.current = false
      }
      confirm()
      loading.current = false
    }
  }

  return (
    <div className={styles.unlock}>
      <div className={styles.item}>
        <Card data={data} click={handleClick}/>
      </div>
    </div>
  )
}

export default Unlock