import {Send} from "./api";
import {message} from "antd";
import {ContractAddress} from "../config";

export class WithdrawRequest {
  task20ID:number = 0
  id:string = ""
  amount:number = 0
  address:string = ""
  chainID:string = ""
  contractAddress:string = ContractAddress
}
class WithdrawResponse {
  errMsg:string = ""
}

// 提现
export async function Withdraw(request: WithdrawRequest) {
  const [ret, err] = await Send("/API/Withdraw", request, WithdrawResponse)
  if (err || ret.errMsg.length > 0) {
    message.warning(err?.message || ret.errMsg)
    return err || ret.errMsg
  }
  return null
}