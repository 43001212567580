import {useEffect, useState} from 'react'
import styles from './styles.module.scss'
import top1Icon from '../../../../assets/images/home/top1.png'
import top2Icon from '../../../../assets/images/home/top2.png'
import top3Icon from '../../../../assets/images/home/top3.png'
// import tokenIcon from '../../../../assets/images/home/token-icon.png'
import tokenIcon from "../../../../assets/images/home/imomo/imomo-coin.png";
import inviteIcon from '../../../../assets/images/home/invite-icon-top.png'
import integralIcon from '../../../../assets/images/home/integral-icon.png'
import {RankType, Search} from "../../../../api/Search";
import {GetUserInfoItem} from "../../../../api/GetUserInfo";
import {formatNumberUnit, scaledDow2w} from "../../../../utils/utils";


interface Item {
  name: string
  num: string
}

interface IProps {
  data?: Item[]
}

const Ranking = (props: IProps) => {
  const [active, setActive] = useState(0)
  const [list, setList] = useState<GetUserInfoItem[]>([])

  const icons: { [key: number]: string } = {
    0: top1Icon,
    1: top2Icon,
    2: top3Icon
  }

  useEffect(() => {
    getData(active).then()
  }, [active])

  const options = [
    {name: 'Token', icon: tokenIcon},
    {name: 'Energy', icon: integralIcon},
    {name: 'Invite', icon: inviteIcon}
  ]

  async function getData(active:number) {
    setList([])
    let type = RankType.SCORE
    if (active === 1) { type = RankType.ENERGY }
    if (active === 2) { type = RankType.INVITE }
    const docs = await Search(type)
    setList(docs)
  }

  function getValue(it:GetUserInfoItem) {
    if (active === 0) { return scaledDow2w(it.Score) }
    if (active === 1) { return it.Energy }
    return it.Invite
  }

  return (
    <div className={styles.ranking}>
      <div className={styles.select}>
        {
          options.map((item, index) => (
            <div
              className={index === active ? styles.option + ` ${styles.option_active}` : styles.option}
              key={index}
              onClick={() => setActive(index)}
            >{item.name}</div>
          ))
        }
      </div>
      <div className={styles.list}>
        {
          list.map((item, index) => {
            return getValue(item) === 0 ? <></> : <div className={styles.item} key={index}>
              <div className={styles.item_wrap}>
                <div className={styles.item_wrap_left}>
                  { icons[index] ? (<img src={icons[index]} alt="icon" className={styles.indexIcon}/>) : (<span className={styles.index}>{index + 1}</span>) }
                </div>
                <span>{item.FirstName + item.LastName}</span>
              </div>
              <div className={styles.num}>
                <span>{formatNumberUnit(getValue(item))}</span>
                <img src={options[active].icon} alt="icon"/>
              </div>
            </div>
          })
        }
      </div>
    </div>
  )
}

export default Ranking
