import {ClassArray} from "../3rdparty/ts-json";
import {Send} from "./api";

class GetWithdrawListRequest {}
class GetWithdrawListResponse {
  errMsg:string = ""
  items: ClassArray<WithdrawListItem> = new ClassArray<WithdrawListItem>(WithdrawListItem)
}
export class WithdrawListItem {
  Id:string = ""
  Uid:string = ""
  Time:number = 0
  TaskId:number = 0
  Amount:number = 0
  ToAddress:string = ""
  ContractAddress:string = ""
  TxID:string = ""
  Done:number = 0
  ScanURL: string = ""
  State: number = 0
}

// 获取提现记录 Withdraw Records
export async function GetWithdrawList() {
  const request = new GetWithdrawListRequest()
  const [ret, err] = await Send("/API/GetWithdrawList", request, GetWithdrawListResponse)
  if (err || ret.errMsg.length > 0) { return [] }
  return ret.items
}