import {Image, Modal} from "antd";
import styles from "./index.module.scss"
import Del from "./del.png"
import {CSSProperties} from "react";

interface Props {
  visible: boolean
  cancel?: () => void
  position?: 'flex-end'|'center'|'flex-start'
  height?: string
  title: string
  content:JSX.Element
  zIndex?:number,
  titleStyle?:CSSProperties
}
export function DModal(props: Props) {

  return <Modal
    centered
    closable={false}
    maskClosable={false}
    keyboard={false}
    zIndex={props.zIndex}
    destroyOnClose={true}
    getContainer={() => document.body}
    open={props.visible}
    wrapClassName={styles.d_modal_wrap}
    onCancel={props.cancel}
    footer={null}>
    <div className={styles.modal_height} style={{alignItems: props.position ? props.position : 'flex-end'}}>
      <div className={styles.modal_content} style={{height: props.height ? props.height : '20px'}}>
        <div className={styles.modal_title} style={props.titleStyle}>{props.title}</div>
        <img className={styles.modal_del} src={Del} onClick={() => props.cancel && props.cancel()} />
        <div className={styles.modal_content_slot}>
          {props.content}
        </div>
      </div>
    </div>
  </Modal>
}
