import {Send} from "./api";
import {message} from "antd";

class UnlockRequest {
  taskID:number = 0
  startTime:number = 0
}
class UnlockResponse {
  errMsg:string = ""
}

// 解锁池子
export async function PostUnlock(taskID:number, startTime:number) {
  const request = new UnlockRequest()
  request.taskID = taskID
  request.startTime = startTime
  const [ret, err] = await Send("/API/Unlock", request, UnlockResponse)
  if (err || ret.errMsg.length > 0) {
    if (ret.errMsg && ret.errMsg.includes("There are unlocked tasks and the current task cannot be unlocked")) {
      return ret.errMsg
    }
    message.warning(err?.message || ret.errMsg)
    return err || ret.errMsg
  }
  return null
}