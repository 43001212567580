import {TGAppUrl} from "../config";
import {GetUserInfoItem} from "../api/GetUserInfo";


/***
 必须是这种链接 https://t.me/demoBot/app?startapp=u12301203990c213138128b2312893
 该方法会提取出startapp的参数
 @param url
 @return 链接中的参数
 ***/
export function getTgWebAppStartParam(url: string): string | null {
    if(url && url.length > 0 && url.includes("?") && url.includes("tgWebAppStartParam")){
        const queryString = url.split('?')[1]?.split('#')[0];
        if (queryString) {
            const pairs = queryString.split('&');
            for (const pair of pairs) {
                const [key, value] = pair.split('=');
                if (decodeURIComponent(key) === 'tgWebAppStartParam') {
                    return decodeURIComponent(value);
                }
            }
            return null
        }
        return null
    }
    return null;
}


export enum LinkBuildType{
    RedEnvelope = "r"
}

export const linkSplit = "-"

export function buildRedEnvelopeLink (rid:string, type:LinkBuildType):string{
    const params = `?startapp=${type}${linkSplit}${rid}`
    return TGAppUrl + params
}

export function getLinkType(link:string):[LinkBuildType, string]{
    return [link.split(linkSplit)[0] as LinkBuildType, link.split(linkSplit)[1]]
}

export function buildUserName(user:GetUserInfoItem){
    if(user){
        return `${user.FirstName}${user.LastName}`
    }
    return "--"
}