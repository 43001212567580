import {Send} from "./api";
import {message} from "antd";

class ClaimInviteEnergyRequest {}
class ClaimInviteEnergyResponse {
  errMsg: string = ''
}

export async function ClaimInviteEnergy() {
  const [ret, err] = await Send("/API/ClaimInviteEnergy", new ClaimInviteEnergyRequest(), ClaimInviteEnergyResponse)
  if (err || ret.errMsg.length > 0) {
    message.warning(err?.message || ret.errMsg)
    return err || ret.errMsg
  }
  return null
}