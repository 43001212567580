import {Send} from "./api";

class ClaimDailyEnergyRewardRequest{

}

class ClaimDailyEnergyRewardResponse{
    errMsg?:string
    result?: ClaimResult
}

export enum ClaimResult{
    ResultOK = 0,  // claim成功
    ResultClaimed = 1, // 已经claim了
    ResultNoMoreQuota = 2,  // 已发放完
}

export const ClaimDailyEnergyReward = async ():Promise<[ClaimDailyEnergyRewardResponse, Error | null]> =>{
    const request = new ClaimDailyEnergyRewardRequest()
    const [ret, err] = await Send("/API/ClaimDailyEnergyReward", request, ClaimDailyEnergyRewardResponse)
    if(ret.errMsg) return [{}, new Error(ret.errMsg)]
    return [ret, err];
}


class GetClaimDailyEnergyRewardStatResponse{
    errMsg?:string
    results?:GetClaimDailyEnergyResult = new GetClaimDailyEnergyResult()
}

export class GetClaimDailyEnergyResult{
    claimed?:number
    total?:number
}

export const GetClaimDailyEnergyRewardStat = async ():Promise<[GetClaimDailyEnergyRewardStatResponse | null, Error | null]> =>{
    const request = new ClaimDailyEnergyRewardRequest()
    const [ret, err] = await Send("/API/GetClaimDailyEnergyRewardStat", request, GetClaimDailyEnergyRewardStatResponse)

    if(err) return [null, err]
    if(ret.errMsg) return [null, new Error(ret.errMsg)]
    return [ret, err];
}