import styles from "./index.module.scss"
import React, {useEffect, useRef, useState} from "react";
import {Login} from "../../api/login";
import Tools from './components/Tools';
import Card from './components/Card'
import {DModal} from "../../components/DModal";
import {ExtractComp} from "./components/ExtractComp";
import Unlock from "./components/Unlock";
import {InviteComp} from "./components/InviteComp";
import Ranking from "./components/Ranking";
import Tasks from "./components/Tasks";
import {GetUserInfo, GetUserInfoItem} from "../../api/GetUserInfo";
import {GetTaskList, GetTaskListResponseItem} from "../../api/GetTaskList";
import {getEnergy, getSeconds, IsLogin, TgLink, scaledDow2w} from "../../utils/utils";
import {GetConfig} from "../../api/GetConfig";
import {getUs} from "../../SoexHelper";
import {RestoreTheLatest} from "../../3rdparty/ts-baselib/src/api/loginapi";
import {Async} from "../../utils/Async";
import {SetLogin401Fn, TimeChangedEvent} from "../../utils/NC";
import {useLocation, useNavigate} from "react-router-dom";
import {PostUnlock} from "../../api/Unlock";
import CoinAnimation from "./components/CoinAnimation";
import UnlockFail from "./components/UnlockFail";
import {GetClaimDailyEnergyRewardStat} from "../../api/ClaimDailyEnergyReward";
import {getLinkType, getTgWebAppStartParam, LinkBuildType, linkSplit} from "../../utils/telegramUtil";
import {message, Tour, TourProps} from "antd";
import {EnvelopSend, RedEnvelopeComp} from "./components/RedEnvelopeComp";
import {APIGetRedEnvelopeInfo, RedEnvelopeInfo} from "../../api/GetRedEnvelopeInfo";
import {APIClaimRedEnvelope, ClaimRedEnvelopeRes} from "../../api/ClaimRedEnvelope";
import LuckCoinModal, {LuckCoinContentRef, LuckCoinStatus} from "./components/LuckCoin";
import PromptComp from "./components/PromptComp";
import {TGBotUrl} from "../../config";


export enum SoexModule {
  EXTRACT = 'extract',
  TASKS = 'tasks',
  INVITE = 'invite',
  RANKING = 'ranking',
  SELL = 'sell',
  UNLOCK = 'unlock',
  ENVELOPE = 'envelope' // 红包
}

interface StepProps {
  onNext?: () => void
  onClose?: () => void
}

const Step1 = (props: StepProps) => {
  const {onNext, onClose} = props
  return (
    <div className={"step_wrap"}>
      <div className={"step_wrap_title step_wrap_title1"}>
        Click to claim real-time rewards.
      </div>
      <div className={"button_wrap"}>
        <div className={"skip_btn"}>
          <div className={"skip_btn_title"} onClick={onClose}>Skip</div>
        </div>
        <div className={"skip_btn next_btn"}>
          <div className={"skip_btn_title"} onClick={onNext}>Next</div>
        </div>
      </div>
    </div>
  )
}

const Step2 = (props: StepProps) => {
  const {onNext, onClose} = props
  return (
    <div className={"step_wrap"}>
      <div className={"step_wrap_title step_wrap_title2"}>
        Complete tasks to earn more energy.
      </div>
      <div className={"button_wrap"}>
        <div className={"skip_btn"}>
          <div className={"skip_btn_title"} onClick={onClose}>Skip</div>
        </div>
        <div className={"skip_btn next_btn"}>
          <div className={"skip_btn_title"} onClick={onNext}>Next</div>
        </div>
      </div>
    </div>
  )
}

const Step3 = (props: StepProps) => {
  const {onNext, onClose} = props
  return (
    <div className={"step_wrap"}>
      <div className={"step_wrap_title step_wrap_title3"}>
        Invite friends to earn more energy.
      </div>
      <div className={"button_wrap"}>
        <div className={"skip_btn"}>
          <div className={"skip_btn_title"} onClick={onClose}>Skip</div>
        </div>
        <div className={"skip_btn next_btn"}>
          <div className={"skip_btn_title"} onClick={onNext}>Next</div>
        </div>
      </div>
    </div>
  )
}

const Step4 = (props: StepProps) => {
  const {onNext, onClose} = props
  return (
    <div className={"step_wrap"}>
      <div className={"step_wrap_title step_wrap_title4"}>
      Click to unlock more capacity.
      </div>
      <div className={"button_wrap button_wrap4"}>
        <div className={"skip_btn"}>
          <div className={"skip_btn_title"} onClick={onClose}>Skip</div>
        </div>
        <div className={"skip_btn next_btn"}>
          <div className={"skip_btn_title"} onClick={onNext}>Next</div>
        </div>
      </div>
    </div>
  )
}

const Step5 = (props: StepProps) => {
  const {onNext, onClose} = props
  return (
    <div className={"step_wrap"}>
      <div className={"step_wrap_title step_wrap_title3"}>
        Red envelopes allow you to get more valid invitations.
      </div>
      <div className={"button_wrap"}>
        <div className={"skip_btn"}>
          <div className={"skip_btn_title"} onClick={onClose}>Skip</div>
        </div>
        <div className={"skip_btn next_btn"}>
          <div className={"skip_btn_title"} onClick={onNext}>Next</div>
        </div>
      </div>
    </div>
  )
}

const Step6 = (props: StepProps) => {
  const {onNext, onClose} = props
  return (
    <div className={"step_wrap"}>
      <div className={"step_wrap_title step_wrap_title3 step_wrap_title6"}>
      You can withdraw tokens to your own wallet.
      </div>
      <div className={"button_wrap"}>
        <div className={"skip_btn"}>
          <div className={"skip_btn_title"} onClick={onClose}>Skip</div>
        </div>
        <div className={"skip_btn next_btn"}>
          <div className={"skip_btn_title"} onClick={onNext}>Done</div>
        </div>
      </div>
    </div>
  )
}

const LuckTypeMap = (type:ClaimRedEnvelopeRes) =>{
  switch (type){
    case ClaimRedEnvelopeRes.ClaimRedEnvelopeSuccess:{
     return  LuckCoinStatus.Opened;
    }
    case ClaimRedEnvelopeRes.ClaimRedEnvelopeExpire:{
      return LuckCoinStatus.Expire;
    }
    case ClaimRedEnvelopeRes.ClaimRedEnvelopeClaimed:{
      return  LuckCoinStatus.Claimed;
    }
    case ClaimRedEnvelopeRes.ClaimRedEnvelopeNotEnough:{
      return  LuckCoinStatus.NotEnough;
    }
  }
  return LuckCoinStatus.Init;
}

export function Home() {
  const location = useLocation();
  const [showInvite, setShowInvite] = useState(false)
  const [showFollowBot, setShowFollowBot] = useState(false)
  const [showExtract, setShowExtract] = useState(false)
  const [showRank, setShowRank] = useState(false)
  const [showTask, setShowTask] = useState(false)
  const [showUnlock, setShowUnlock] = useState(false)
  const [showEnvelope, setShowEnvelope] = useState(false)
  const [showSend, setShowSend] = useState(false)
  const [redEnvelopLink, setRedEnvelopLink] = useState("")
  const [showNotSkiplock, setShowNotSkiplock] = useState(false)
  const [userInfo, setUserInfo] = useState<GetUserInfoItem>(new GetUserInfoItem())
  const [totalRate, setTotalRate] = useState(0)
  const [taskList, setTaskList] = useState<GetTaskListResponseItem[]>([
    // {
    //   taskID:1
    // } as GetTaskListResponseItem,
    // {
    //   taskID:2
    // } as GetTaskListResponseItem,
  ])
  const [inviteData, setInviteData] = useState<GetTaskListResponseItem>(new GetTaskListResponseItem())
  const loading = useRef(false)
  const divRef = useRef<any>(null)
  const [envelopeVisible, setEnvelopeVisible] = useState(false);
  const navigate = useNavigate();
  const redEnvelopeRef = useRef<LuckCoinContentRef>(null);

  // const [tourSteps, setTourSteps] = useState<TourStepProps[]>([]);
  const [openTour, setOpenTour] = useState(false);
  const [claimStat, setClaimStat] = useState("");
  const [currentStep, setCurrentStep] = useState(0) // 0 开始

  const [claimStatus, setClaimStatus] = useState<ClaimRedEnvelopeRes>();

  const timrRef = useRef<NodeJS.Timeout>()
  const timerTask = useRef<NodeJS.Timeout>()
  const versionId = useRef(0)

  const tourStepOption = {
    title: null,
    closeIcon:null,
    nextButtonProps:{
      children:null
    },
    prevButtonProps:{
      children:null,
      style:{
        display:"none"
      }
    },
  }

  const tourSteps: TourProps['steps'] = [
    {
      ...tourStepOption,
      description: <Step1 onNext={() => handleNext(0)} onClose={() => setOpenTour(false)}/>,
      target: document.getElementById(`claim-btn-1`),
    },
    {
      ...tourStepOption,
      description: <Step2 onNext={() => handleNext(1)} onClose={() => setOpenTour(false)} />,
      target: document.getElementById(`taskItem0`),
    },
    {
      ...tourStepOption,
      description: <Step3 onNext={() => handleNext(2)} onClose={() => setOpenTour(false)} />,
      target: document.getElementById(`inviteIcon`),
      placement: 'bottomLeft'
    },
    {
      ...tourStepOption,
      description: <Step4 onNext={() => handleNext(3)} onClose={() => setOpenTour(false)} />,
      target: document.getElementById(`claim-btn-2`),
    },
    {
      ...tourStepOption,
      description: <Step5 onNext={() => handleNext(4)}onClose={() => setOpenTour(false)} />,
      target: document.getElementById(`envelopeIcon`),
    },
    {
      ...tourStepOption,
      description: <Step6 onNext={() => handleNext(5)}onClose={() => setOpenTour(false)} />,
      target: document.getElementById(`extractIcon`),
    },
  ]

  const [redEnvelopeInfo, setRedEnvelopeInfo] = useState<RedEnvelopeInfo>();

  useEffect(() => {
    getLogin().then()
    const preventDefault = (event:any) => {
      event.preventDefault();
    };
    if (divRef.current) {
      divRef.current.addEventListener('touchmove', preventDefault, { passive: false });
    }

    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('touchmove', preventDefault);
      }
      timrRef.current && clearTimeout(timrRef.current)
    };
  }, [])

  const getRedEnvelope = () =>{
    Async( async ()=>{
      const res = getTgWebAppStartParam(window.location.href)
      if(res && res.includes(linkSplit)){
        const [type, rid] = getLinkType(res);
        if(type === LinkBuildType.RedEnvelope){
          const [redEnvelopeRes, err] = await APIGetRedEnvelopeInfo([rid])
          if(err) return openTourPage();
          const users = await GetUserInfo([redEnvelopeRes[0].userId!])
          if (users.length <= 0) return openTourPage();
          const user = users[0]
          const redEnvelope = redEnvelopeRes[0];
          redEnvelope.userName = user.FirstName + user.LastName
          setRedEnvelopeInfo(redEnvelope);
          // 打开红包弹窗
          setEnvelopeVisible(true);
          const nowTime = new Date().getTime();
          if(redEnvelope.hasOpened || (redEnvelope.total === redEnvelope.claimedTotal) || (nowTime >= (redEnvelope.expired ?? 0))){
            loading.current = true;
            const [claimRedEnvelope, err] = await APIClaimRedEnvelope(`${redEnvelope?.id}`);
            if(err) {
              loading.current = false;
              return ;
            }
            let type = LuckTypeMap(claimRedEnvelope.code!);
            setTimeout(()=>{
              redEnvelopeRef!.current!.openEnvelope(`${scaledDow2w(parseInt(redEnvelopeInfo?.coinAmount ?? "0"))}`, type);
              loading.current = false;
            }, 0)
          }
        }
      } else {
        openTourPage();
      }
    })
  }

  const openTourPage = () =>{
    // 引导页
    if (taskList.length && localStorage.getItem('guide-mask') !== '1') {
      timrRef.current = setTimeout(() => {
        setOpenTour(true)
        localStorage.setItem('guide-mask', '1')
      }, 500)
    }
  }

  // 打开红包
  const openRedEnvelope = async ():Promise<void> =>{
    if(loading.current) return ;
    loading.current = true;
    const [claimRedEnvelope, err] = await APIClaimRedEnvelope(`${redEnvelopeInfo?.id}`);
    if(err){
      message.info(err.message);
      loading.current = false;
      return ;
    };
    if(claimRedEnvelope.code === ClaimRedEnvelopeRes.ClaimRedEnvelopeSuccess){
      // 改变状态为领取成功
      updateData().then();
    }
    let type = LuckTypeMap(claimRedEnvelope.code!);
    redEnvelopeRef!.current!.animateOpen(`${scaledDow2w(parseInt(redEnvelopeInfo?.coinAmount ?? "0"))}`, type);
    loading.current = false;
  }


  async function getLogin() {
    await RestoreTheLatest()
    const win = window as any
    // 检查是否在 Telegram 环境中运行
    if (!win.Telegram?.WebApp) {
      return
    }

    const tg = win.Telegram.WebApp;
    tg.expand();
    const user = tg.initDataUnsafe?.user;
    if (!user) {
      return
    }
    // console.log(tg, '用户信息')

    SetLogin401Fn(async () => {
      const err = await Login(tg.initData)
      if (err) { return }
      await getData()
    })

    getUs().nf.get().set401Delegate(net => {
      Async(async () => {
        const err = await Login(tg.initData)
        if (err) { return }
        await getData()
      })
    })

    if (!(await IsLogin(user.id?.toString()))) {
      const err = await Login(tg.initData)
      if (err) { return }
    }

    await getData()
  }

  async function getData() {
    await GetConfig()
    getUserInfo().then()
    await getTaskList().then()
    getRedEnvelope()
    handleQuery()
    getDailyClaimStat().then()
  }

  function handleQuery() {
    try{
      console.log(JSON.stringify(location), "路径信息")
      const query = location.hash
      const obj:any = {}
      const arr = query.split("?")
      if (arr.length !== 2) return
      const arr1 = arr[1].split("&")
      for(let it of arr1) {
        const ketVal = it.split("=")
        if (ketVal.length !== 2) {
          continue
        }
        obj[ketVal[0]] = ketVal[1]
      }
      if (!obj.to) return
      if (obj.to === 'tasks') { setShowTask(true) }
      if (obj.to === 'draw') { setShowExtract(true) }
      setTimeout(() => {
        removeQueryParameter('to')
      }, 3000)
    } catch (e){

    }
  }

  const removeQueryParameter = (key: string) => {
    // @ts-ignore
    const url = new URL(window.location);
    url.href = window.location.href.split("?")[0]
    window.history.replaceState({}, '', url);
  };

  async function updateData(code?: number) {
    await Promise.allSettled([
      getUserInfo(),
      getTaskList(code)
    ])
    setShowExtract(false)
    setShowUnlock(false)
  }
  function UnlockFailFn() {
    setShowNotSkiplock(false)
  }

  // 用户信息
  async function getUserInfo() {
    getDailyClaimStat().then()
    const users = await GetUserInfo([getUs().getUid()])
    if (users.length <= 0) return
    const user = users[0]
    if(!user.ChatID){
      setShowFollowBot(true)
    }
    setUserInfo(user)
  }
  // 任务列表
  async function getTaskList(code?: number) {
    let tasks = await GetTaskList()
    const nowTime = Date.now()
    const totalRate = tasks.filter(it => {
      const totalTime = parseInt((it.max / it.rate).toString())
      const t = it.startTime === 0 ? totalTime : totalTime - getSeconds(nowTime - it.startTime * 1000)
      const num = (totalTime - t) * it.rate
      return num < it.max && it.startTime > 0
    }).reduce((total, current) => {
      return total + current.rate
    }, 0)
    if (code && code !== versionId.current) { return }
    setTotalRate(scaledDow2w(totalRate))
    setTaskList(prev => [...tasks].sort((a, b)=>{
     return a.taskID - b.taskID
    }))
    await UnlockFirst(tasks.length > 0 ? tasks[0] : null)
    if (timerTask.current) return
    startInterval()
  }

  function startInterval() {
    timerTask.current = setInterval(() => {
      getUs().nc.post(new TimeChangedEvent([])).then()
    }, 1000)
  }

  const handleNext = (step: number) => {
    if (step === 0) {
      setShowTask(true)
    }
    if (step === 1) {
      setShowTask(false)
    }
    if (step === 5) {
      setOpenTour(false)
      return
    }
    timrRef.current = setTimeout(() => {
      setCurrentStep(step + 1)
    }, 500)
  }

  const onChangetourstep = (step: number) => {
    console.log(step, 'onChangetourstep')
  }

  useEffect(()=>{
    const res = getTgWebAppStartParam(window.location.href)
    if(!res && taskList.length > 0){
      openTourPage()
    }
  }, [taskList, envelopeVisible])

  async function UnlockFirst(item: GetTaskListResponseItem | null) {
    if (!item) return
    if (item.lock === 0) return
    const err = await PostUnlock(item.taskID, getSeconds(Date.now()))
    if (err) return
    await getTaskList()

  }

  function handleClick(type:SoexModule, item?: GetTaskListResponseItem) {
    if (type === SoexModule.EXTRACT) { return setShowExtract(true) }
    if (type === SoexModule.INVITE) { return setShowInvite(true) }
    if (type === SoexModule.RANKING) { return setShowRank(true) }
    if (type === SoexModule.TASKS) { return setShowTask(true) }
    if (type === SoexModule.ENVELOPE) { return setShowEnvelope(true) }
    if (type === SoexModule.UNLOCK) {
      handleUnlockData(item!).then()
      return setShowUnlock(true)
    }
    if (type === SoexModule.SELL) {
      return TgLink("https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00", 'web')
    }
  }

  async function handleUnlockData(item: GetTaskListResponseItem) {
    setInviteData(item)
  }

  async function handleUnlock(str?:string) {
    if (!str) {
      await updateData()
      return
    }
    if (str.includes("There are unlocked tasks and the current task cannot be unlocked")) {
      setShowUnlock(false)
      setShowNotSkiplock(true)
      return
    }
    await updateData()
  }


  async function handleReceive(it: GetTaskListResponseItem) {
    versionId.current = Math.random() * 100
    await updateData(versionId.current)
  }

  const getDailyClaimStat = async () =>{
    const [res, err] = await GetClaimDailyEnergyRewardStat()
    if(err) return;
    if(res?.results){
      setClaimStat(`${res.results.claimed}/${res.results.total}`);
    }
  }

  const openBot = () =>{
    const res = getTgWebAppStartParam(window.location.href);
    let inviteCode = "1";
    if(res && res?.includes(linkSplit)){
      const [type, code] = getLinkType(res);
      inviteCode = code
    }
    const link = `${TGBotUrl}=${inviteCode}`
    setShowFollowBot(false);
    TgLink(link, "tg");
    closeTg();
  }

  const showSendRed = (link:string) => {
    setShowEnvelope(false);
    setRedEnvelopLink(link);
    setShowSend(true)
  }

  const closeTg = () =>{
    const win:any = window
    if(win?.Telegram?.WebApp?.close){
      win.Telegram.WebApp.close();
    }
  }

  return (
    <div className={styles.home}>
      <div className={styles.block_black}></div>
      <div id={"main-content"} style={{
        overflow: openTour ? "hidden" : "auto"
      }} className={styles.content}>
        <Tools click={handleClick} score={userInfo.Score} energy={userInfo.Energy} rate={totalRate} />
        <div className={styles.list}>
          <div className={styles.coin_avatar}>
            <div className={styles.dv} ref={divRef}>
              <CoinAnimation />
            </div>
          </div>
          <div className={styles.wrap_ist}>
            {
              taskList.map((it, index) => {
                return (
                  <div
                    className={styles.item}
                    key={it.taskID}
                    id={'taskCard' + index}
                  >
                    <Card key={it.startTime} item={{...it, taskID: it.taskID}} level={index + 1} click={handleClick} recive={handleReceive}/>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.empty}></div>
      </div>
      <DModal visible={showInvite} title='Invite' content={<InviteComp confirm={getUserInfo} />} height='95%' cancel={() => setShowInvite(false)} />
      <DModal visible={showExtract} title='Extract' content={<ExtractComp name={userInfo.FirstName + userInfo.LastName} confirm={updateData} score={userInfo.Score} energy={userInfo.Energy} />} height='95%' cancel={() => setShowExtract(false)} />
      <DModal visible={showRank} title='Ranking' content={<Ranking />} height='95%' cancel={() => setShowRank(false)} />
      { showTask && <DModal visible={showTask} title='Tasks' content={<Tasks claimStat={claimStat} condition={userInfo.Ids} confirm={getUserInfo} />} height='95%' cancel={() => setShowTask(false)} /> }
      { showEnvelope && <DModal
        visible={showEnvelope}
        title='Red envelope'
        content={ <RedEnvelopeComp showSendRed={showSendRed}  confirm={updateData} score={userInfo.Score} /> }
        height='95%'
        cancel={() => setShowEnvelope(false)}
      /> }

      {
        showSend && <EnvelopSend link={redEnvelopLink} show={showSend} cancel={() => setShowSend(false)} />
      }

      {showUnlock && <DModal
        visible={showUnlock}
        title='Unlock new capacity'
        titleStyle={{fontSize: '18px'}}
        height='80%'
        cancel={() => setShowUnlock(false)}
        content={<Unlock
          rate={inviteData.rate}
          energy={getEnergy(inviteData.condition, 'energy')}
          score={getEnergy(inviteData.condition, 'score')}
          type={inviteData.condition.includes('energy') ? 'energy' : 'score'}
          taskId={inviteData.taskID}
          confirm={handleUnlock}
        />}
      />}
      { showNotSkiplock && <DModal
        visible={showNotSkiplock}
        title='Unlock new capacity'
        position='center'
        height="250px"
        titleStyle={{fontSize: '18px'}}
        cancel={() => setShowNotSkiplock(false)}
        content={<UnlockFail confirm={UnlockFailFn} />}
      />}

      <Tour
        mask={{
          style:{
            touchAction:"manipulation"
          },
          color:"#000000C7"
        }}
        closeIcon={false}
        arrow={false}
        animated={true}
        open={openTour}
        onClose={() => setOpenTour(false)}
        steps={tourSteps}
        disabledInteraction={true}
        onChange={onChangetourstep}
        current={currentStep}
        scrollIntoViewOptions={false}
      />
      <LuckCoinModal
        visible={envelopeVisible}
        ref={redEnvelopeRef}
        onClose={() => {
          setEnvelopeVisible(false);
          navigate("/")
          openTourPage();
        }}
        isExpire={claimStatus === ClaimRedEnvelopeRes.ClaimRedEnvelopeExpire}
        isOpen={claimStatus === ClaimRedEnvelopeRes.ClaimRedEnvelopeClaimed }
        value={redEnvelopeInfo?.coinAmount ?? "--"}
        coin="momo"
        sender={`${redEnvelopeInfo?.userName ?? "--"}`}
        onOpen={openRedEnvelope}
      />
      <DModal zIndex={9999} visible={showFollowBot} title='Prompt' position={"center"} content={<PromptComp onClose={()=>{
        setShowFollowBot(false)
        closeTg();
      }} openBot={()=>openBot()}/>} height='min-content !important' cancel={() => {
        setShowFollowBot(false)
        closeTg()
      }} />
    </div>
  )
}
