import {Send} from "./api";

class CheckJoinTGGroupRequest {
  chatID: string = ""
}
class CheckJoinTGGroupResponse {
  errMsg:string = ""
  isMember:boolean = false
}

export async function CheckJoinTGGroup() {
  const request = new CheckJoinTGGroupRequest()
  const [ret, err] = await Send("/API/CheckJoinTGGroup", request, CheckJoinTGGroupResponse)
  if (err || ret.errMsg.length > 0) {
    return err || ret.errMsg
  }
  return null
}