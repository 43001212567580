import { Send } from "./api";
import {ClassArray} from "../3rdparty/ts-json";
import {GetUserInfo, GetUserInfoItem} from "./GetUserInfo";
import {buildUserName} from "../utils/telegramUtil";
import {getYearMonthDayTimeStampNew} from "../utils/utils";

// 请求类
class GetRedEnvelopeTransactionsRequest {}


enum OpType{
    OpCreate = "create", // 创建
    OpClaim  = "claim", // 领取
    OpRefund = "refund", // 退款
}

// 响应项类
export class GetRedEnvelopeTransactionsResponseItem {
    id?: string;
    op?: string;
    amount?: number;
    userId?: string;
    side?: string; // in out
    time?: number;
    rId:string = "";
    from:string=""
}

// 响应类
class GetRedEnvelopeTransactionsResponse {
    errMsg?: string;
    Items?: ClassArray<GetRedEnvelopeTransactionsResponseItem> = new ClassArray<GetRedEnvelopeTransactionsResponseItem>(GetRedEnvelopeTransactionsResponseItem);
}


enum SideType{
    add = "+",
    sub = "-"
}

export class RedEnvelopeTransactionItem{
    type?:OpType = OpType.OpClaim;
    balance?:number = 0;
    title?:string = "";
    side?:SideType = SideType.add;
    time?:string;
    rId?:string
}

// 获取红包流水
export const APIGetRedEnvelopeTransactions = async (): Promise<[RedEnvelopeTransactionItem[], Error | null]> => {
    const request = new GetRedEnvelopeTransactionsRequest();
    const [ret, err] = await Send("/API/GetRedEnvelopeTransactions", request, GetRedEnvelopeTransactionsResponse);

    if (err) {
        return [[], err];
    }

    if (ret.errMsg) {
        return [[], new Error(ret.errMsg)];
    }

    if(ret.Items && ret.Items.length > 0){
        const uids = ret.Items.map(item=>item.from);
        const userInfos = await GetUserInfo(uids);
        const userMap = userInfos.reduce((map:{
            [key: string]: GetUserInfoItem;
        }, user) => {
            map[user.Uid] = user;
            return map;
        }, {});

        const list = ret.Items.sort((a, b)=>(b.time ?? 0) - (a.time ?? 0));

        const resList = list.map(item=>{
            const redEnvelopeTransactionItem = new RedEnvelopeTransactionItem();
            redEnvelopeTransactionItem.rId = item.rId;
            redEnvelopeTransactionItem.balance = item.amount ?? 0;
            redEnvelopeTransactionItem.side = item.side === "in" ? SideType.add : SideType.sub;
            redEnvelopeTransactionItem.type = item.op as OpType;
            redEnvelopeTransactionItem.time = getYearMonthDayTimeStampNew(item.time);
            let title = "";
            if(redEnvelopeTransactionItem.type === OpType.OpClaim){
                title = `Receive red envelopes from ${buildUserName(userMap[item.from ?? ""])}`
            }

            if(redEnvelopeTransactionItem.type === OpType.OpCreate){
                title = `Send out red envelopes`
            }

            if(redEnvelopeTransactionItem.type === OpType.OpRefund){
                title = `Red envelope refund`
            }
            redEnvelopeTransactionItem.title = title;
            return redEnvelopeTransactionItem;
        })
        return [resList, null]
    }
    return [[], null];
}