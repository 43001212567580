import styles from './styles.module.scss'
import addIcon from '../../../../assets/images/home/add-icon.png'
import extractIcon from '../../../../assets/images/home/extract-icon.png'
import tasksIcon from '../../../../assets/images/home/tasks-icon.png'
import sellIcon from '../../../../assets/images/home/sell-icon.png'
import inviteIcon from '../../../../assets/images/home/invite-icon.png'
import rankingIcon from '../../../../assets/images/home/ranking-icon.png'
import integralIcon from '../../../../assets/images/home/integral-icon.png'
import clockIcon from '../../../../assets/images/home/clock-icon.png'
// import tokenIcon from '../../../../assets/images/home/token-icon.png'
import tokenIcon from '../../../../assets/images/home/imomo/imomo-coin.png'
import envelopeIcon from '../../../../assets/images/home/envelope.png'
import {SoexModule} from "../../index";
import {formatNumberUnit, formatNumberWithCommas, scaledDow2w} from "../../../../utils/utils";


interface Props {
  click: (type: SoexModule) => void
  score: number
  energy:number
  rate: number
}

function getN(num:number) {
  return num.toLocaleString()
}

const Tools = (props: Props) => {
    return (
        <div className={styles.tools}>
            <div className={styles.top}>
                <div id={"coinBalance"} className={styles.item}>
                    <img src={tokenIcon} alt="tokenIcon" />
                    <span>{formatNumberUnit(scaledDow2w(props.score))}</span>
                </div>
                <div className={styles.item}>
                    <img src={integralIcon} alt="integralIcon" />
                    <span>{formatNumberWithCommas(props.energy)}</span>
                    <img className={styles.addIcon} src={addIcon} alt="addIcon" onClick={() => props.click(SoexModule.INVITE)} />
                </div>
                <div className={styles.item}>
                    <img src={clockIcon} alt={clockIcon} />
                    <span>+{props.rate}/S</span>
                </div>
            </div>
            <div className={styles.left}>
                <div className={styles.item} onClick={() => props.click(SoexModule.EXTRACT)}>
                    <img src={extractIcon} alt="extractIcon" id='extractIcon'/>
                </div>
                <div className={styles.item} onClick={() => props.click(SoexModule.TASKS)}>
                    <img id={"task-btn-icon"} src={tasksIcon} alt="tasksIcon" />
                </div>
                <div className={`${styles.item} gray_filter`}>
                    <img src={sellIcon} alt="sellIcon" />
                </div>
            </div>
            <div className={styles.right}>
              <div className={styles.item} onClick={() => props.click(SoexModule.INVITE)}>
                  <img src={inviteIcon} alt="extract" id='inviteIcon'/>
              </div>
              <div className={styles.item} onClick={() => props.click(SoexModule.RANKING)}>
                  <img src={rankingIcon} alt="extract" id='rankingIcon'/>
              </div>
              <div className={styles.item} onClick={() => props.click(SoexModule.ENVELOPE)}>
                <img src={envelopeIcon} alt="envelope" id='envelopeIcon'/>
              </div>
            </div>
        </div>
    )
}

export default Tools
