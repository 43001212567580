import React, {createContext, useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {Home} from "./pages/Home"
import './App.css';
import VConsole from 'vconsole';
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import zhTW from "antd/lib/locale/zh_TW";
// import en from "./did-language/en.json";
// import zh from "./did-language/zh-CN.json";
// import zhT from "./did-language/zh-CN-rTw.json"
import {Env} from "./config";
import {setStreamClientConstructor} from "./3rdparty/ts-baselib";
import {Streamer} from "./Streamer";
import './theme.scss'
import {Async} from "./utils/Async";
import {RestoreTheLatest} from "./3rdparty/ts-baselib/src/api/loginapi";
import {Me} from "./3rdparty/ts-baselib/src/userspace";

export enum LangType {
  US = 'en',
  CN = 'zhHans',
  TW = 'zhHant'
}

setStreamClientConstructor(Streamer);

export const SetLanguage = createContext<{
  setLanguage: Function
}>({
  setLanguage: (language:string) => {}
});

function App() {
  useEffect(() => {
    // if (Env !== "pro") {
    //   new VConsole();
    // }

  }, []);
  const setLanguage = (language: LangType) => {
    if (currentLanguage !== language && language) {
      setCurrentLanguage(language)
    }
  }

  // const messages = {
  //   'en': en,
  //   'zhHans': zh,
  //   'zhHant': zhT
  // };
  const [currentLanguage, setCurrentLanguage] = useState<LangType>(LangType.US);

  const buildLanguage = (language:LangType) => {
    const languageMap = new Map([
      [LangType.US, enUS],
      [LangType.CN, zhCN],
      [LangType.TW, zhTW],
    ]);
    if (!languageMap.has(language)) {
      return languageMap.get(LangType.US)
    }
    return languageMap.get(language);
  }

  return (
    <SetLanguage.Provider
      value={{
        setLanguage: setLanguage
      }}
    >
      <ConfigProvider locale={buildLanguage(currentLanguage)}>
        {/*<IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>*/}
        <IntlProvider locale={currentLanguage}>
          <Routes>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/" element={<Home />}></Route>
          </Routes>
        </IntlProvider>
      </ConfigProvider>
  </SetLanguage.Provider>

  );
}

export default App;
