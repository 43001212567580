import {Send} from "./api";
import {message} from "antd";

class CheckFollowPepeBotTwitterRequest {}
class CheckFollowPepeBotTwitterResponse {
  errMsg:string = ""
}

export async function CheckFollowPepeBotTwitter() {
  const [ret, err] = await Send("/API/CheckFollowPepeBotTwitter", {}, CheckFollowPepeBotTwitterResponse)
  if (err || ret.errMsg.length > 0) {
    return err || ret.errMsg
  }
  return null
}