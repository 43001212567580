import styles from "./index.module.scss";
import {DBtn} from "../../../../components/DBtn";

const PromptComp = ({onClose, openBot}:{onClose:()=>void, openBot:()=>void}) =>{
  return <div className={styles.prompt_wrap}>
    <div className={styles.card_title}>Please follow the bot first to complete the registration</div>
    <div className={styles.btn_wrap}>
      <div><DBtn
        title='Cancel'
        onclick={onClose}
        titleStyle={{
          textShadow: "-1px -1px 0 #0F7481, 1px -1px 0 #0F7481, -1px 1px 0 #0F7481, 1px 1px 0 #0F7481, 0px 2px 0px #0F6671",
        }}
        style={{
          width: '148px',
          height: '44px',
          background: "linear-gradient( 180deg, #38ABFF 0%, rgba(19,222,243,0.82) 100%)",
          boxShadow: "0px 6px 4px 0px rgba(84,102,109,0.67), 0px 3px 0px 0px #0F6671, inset 0px 0px 12px 0px #50FFF9"
        }} /></div>
      <div><DBtn title='Go' style={{width: '148px', height: '44px'}} onclick={openBot} /></div>
    </div>
  </div>
}

export default PromptComp;
