import {Send} from "./api";
import {PostJsonLogin, PostJsonNoToken} from "../3rdparty/ts-baselib";
import {getUs, setUs} from "../SoexHelper";
import { v4 as uuidv4 } from 'uuid';
import {getLinkType, getTgWebAppStartParam, LinkBuildType, linkSplit} from "../utils/telegramUtil";

class LoginRequest {
  clientID: string= ""
  initData:string = ""
  code?:string
}

class Response {
  uid: string = ""
  token: string = ""
  errMsg:string = ''
}

export async function Login(initData:string) {
  const link = getTgWebAppStartParam(window.location.href)
  const request = new LoginRequest()
  request.initData = initData
  if(link && link.includes(linkSplit)){
    const [type, code] = getLinkType(link)
    if(type === LinkBuildType.RedEnvelope){
      request.code = link
    }
  }
  request.clientID = uuidv4().replace(/-/g, "")
  const req = {uri: "/API/Login", content: {data: request}}
  const id = getUs()
  const [newUserSpace, err] = await PostJsonLogin(req, id);
  if (err) {
    console.warn("Login err", err);
    return err;
  }

  await setUs(newUserSpace)

  return null



}
