import {ClassArray} from "../3rdparty/ts-json";
import {Send} from "./api";

class GetTaskListRequest {}
export class GetTaskListResponseItem {
  taskID:number = 0
  max:number = 0
  rate:number = 0 // 速率，每秒可以获得多少
  startTime:number = 0
  lock:number = 0 // 0 -- 解锁状态 1-- 锁定状态
  condition:string = ""
}

class GetTaskListResponse {
  errMsg:string = ""
  tasks:ClassArray<GetTaskListResponseItem> = new ClassArray<GetTaskListResponseItem>(GetTaskListResponseItem)
}

// 获取首页任务列表
export async function GetTaskList():Promise<GetTaskListResponseItem[]> {
  const request = new GetTaskListRequest()
  const [ret, err] = await Send("/API/GetTaskList", request, GetTaskListResponse)
  if (err || ret.errMsg.length > 0) { return [] }
  return ret.tasks
}