// import pepeIcon from "../../../../images/pepe.png";
import pepeIcon from "../../../../assets/images/home/imomo/imomo-coin.png";
import arrowIcon from "./arrow.png"
import arrowWhite from "./arrow_white.png"
import styles from "./index.module.scss"
import React, {useEffect, useState} from "react";
import {DCard} from "../../../../components/DCard";
import {DBtn} from "../../../../components/DBtn";
import {Input, message} from "antd";
import {formatNumberUnit, scaledDow2w, TgLink} from "../../../../utils/utils";
import copy from "copy-to-clipboard";
import {DModal} from "../../../../components/DModal";
import classnames from "classnames";
import {APICreateRedEnvelope, CreateRedEnvelopeCodeRes} from "../../../../api/CreateRedEnvelope";
import {APIGetRedEnvelopeTransactions, RedEnvelopeTransactionItem} from "../../../../api/GetRedEnvelopeTransactions";
import {buildRedEnvelopeLink, LinkBuildType} from "../../../../utils/telegramUtil";
import DEmpty from "../../../../components/DEmpty";
import {LoadingOutlined} from "@ant-design/icons";
import {APIGetRedEnvelopeClaimLog, RedEnvelopeClaimLogItem} from "../../../../api/GetRedEnvelopeClaimLog";
import {APIGetRedEnvelopeInfo, RedEnvelopeInfo} from "../../../../api/GetRedEnvelopeInfo";
import {scaleNum} from "../../../../config";

enum CompType {
  RECORD = 'record', // 记录
  EXTRACT = 'extrace' // 提取
}

interface Props{
  score:number,
  confirm:(link:string) => void,
  openRecord:() => void,
  refresh:()=>void,
}

function ExtractModule({score, confirm, openRecord, refresh}:Props) {
  const [price, setPrice] = useState("")
  const [envelopNum, setEnvelopNum] = useState("")
  const [amount, setAmount] = useState("0")
  const [disable, setDisable] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (price.trim().length > 0 && price !== "0" && envelopNum.trim().length > 0 && envelopNum !== "0") {
      setDisable(false)
      calcAmount();
    } else {
      setDisable(true)
    }
  }, [price, envelopNum])

  const onEnvelopNumChange = (value:string) =>{
    if (/^\d*$/.test(value)) {
      setEnvelopNum(value);
    }
  }

  const onPriceChange = (value:string) =>{
    if (/^\d*$/.test(value)) {
      setPrice(value);
    }
  }

  const calcAmount = () =>{
    if(price && envelopNum){
      setAmount(`${parseInt(price) * parseInt(envelopNum)}`);
    }
  }

  // 创建红包
  const createRedEnvelop = async () =>{
    if(envelopNum && price){
      setLoading(true);
      const [createRes, err] = await APICreateRedEnvelope(parseInt(envelopNum), parseInt(price) * scaleNum,);
      setLoading(false);
      if(err) return message.info(err.message);
      if(createRes.code === CreateRedEnvelopeCodeRes.codeSuccess){
        // 创建红包成功 打开分享 使用rid字段拼接链接
        confirm(buildRedEnvelopeLink(createRes.rid!, LinkBuildType.RedEnvelope))
        refresh();
      }
      if(createRes.code === CreateRedEnvelopeCodeRes.codeInsufficientBalance){
        message.info("Your momo balance is insufficient!")
      }
    }
  }

  return <div className={styles.envelope_module_wrap}>
    <div className={styles.envelope_module_top}>
      <div className={styles.envelope_module_user}>
        <img src={pepeIcon} alt=""/>
        <div className={styles.envelope_module_user_dear}>momo balance</div>
        <div className={styles.envelope_module_user_price}>{formatNumberUnit(score)}</div>
      </div>
      <div className={styles.envelope_module_text}>Number of red envelopes</div>
      <div>
        <Input className={envelopNum.length <= 0 ? styles.select_input_none : styles.select_input} placeholder='Input...' value={envelopNum} onChange={(e) => onEnvelopNumChange(e.target.value)}/>
      </div>

      <div className={styles.envelope_module_text}>Quantity/Piece</div>
      <div>
        <Input type="text"  className={price.length <= 0 ? styles.select_input_none : styles.select_input} placeholder='Input...' value={price} onChange={(e) => onPriceChange(e.target.value)}/>
      </div>

      <div className={styles.envelope_module_red}>
        <div className={styles.envelope_module_num}>{amount}</div>
        <div className={styles.envelope_module_tip}>Total issued</div>
      </div>
      <div className={styles.envelope_module_desc}>*Unregistered friends who register through your red envelope link will automatically become your valid invitation and get the invitation energy</div>

    </div>
    <div className={styles.select_bot_btn}>
      <DBtn
        title='Emit'
        onclick={createRedEnvelop}
        loading={loading}
        disabled={disable}
        style={{
          background: "linear-gradient( 180deg, #FFB5AA 0%, #E2392A 100%)",
          boxShadow: "0px 6px 4px 0px rgba(216,106,106,0.67), 0px 3px 0px 0px #990000, inset 0px 0px 12px 0px #CA0A0A",
        }}
      />
      <div className={styles.envelope_module_record_open} onClick={openRecord}>
        Red Envelope Record
        <img src={arrowIcon} alt=""/>
      </div>
    </div>
  </div>
}

export function RedEnvelopeComp({score, confirm, showSendRed}: {score:number, confirm:() => void, showSendRed:(link:string)=>void}) {
  const [showEnvelopeRecord, setShowEnvelopeRecord] = useState(false)
  return <div className={styles.extract_comp_wrap}>
    <ExtractModule score={score} refresh={confirm} confirm={showSendRed} openRecord={() => setShowEnvelopeRecord(true)}/>
    <EnvelopeRecord show={showEnvelopeRecord} cancel={() => setShowEnvelopeRecord(false)} />
  </div>
}

const shareLinkPre = "I sent a momo red envelope and come and collect it!!! "

export function EnvelopSend({show, link, cancel}:{show: boolean, link:string, cancel:() => void}) {
  async function handleCopy() {
    const ret = copy(shareLinkPre + link)
    if (ret) {
      await message.success('Copy Link: Successful')
      return
    }
    message.error('Copy Link: Failed')
  }

  function handleShare() {
    const shareLink = `https://t.me/share/url?url=${encodeURIComponent(shareLinkPre) + link}`
    TgLink(shareLink, 'tg')
  }

  const getContext = <div>
    <div className={styles.envelope_card_title}>Share the exclusive link of the red envelope to your friends to receive!</div>
    <div className={styles.envelope_card_card}>{link}</div>
    <div className={styles.envelope_btn_wrap}>
      <div><DBtn
        title='Copy'
        onclick={handleCopy}
        titleStyle={{
          textShadow: "-1px -1px 0 #0F7481, 1px -1px 0 #0F7481, -1px 1px 0 #0F7481, 1px 1px 0 #0F7481, 0px 2px 0px #0F6671",
        }}
        style={{
          width: '148px',
          height: '44px',
          background: "linear-gradient( 180deg, #38ABFF 0%, rgba(19,222,243,0.82) 100%)",
          boxShadow: "0px 6px 4px 0px rgba(84,102,109,0.67), 0px 3px 0px 0px #0F6671, inset 0px 0px 12px 0px #50FFF9"
        }} /></div>
      <div><DBtn title='Share' style={{width: '148px', height: '44px'}} onclick={handleShare} /></div>
    </div>
  </div>

  const cell = <div className={styles.envelope_public}>
    <DCard context={getContext} />
    <div className={styles.envelope_desc}>
      Effective time of red envelope
      <span>24H</span>
    </div>

  </div>

  return <div className={styles.envelop_send}>
    <DModal
      visible={show}
      title='Successfully sent'
      position='center'
      height="300px"
      titleStyle={{fontSize: '18px'}}
      cancel={cancel}
      content={cell}
    />
  </div>
}

function EnvelopeRecord({show, cancel}:{show: boolean, cancel:() => void}) {
  const [showEnvelopeDetail, setShowEnvelopeDetail] = useState(false)
  const [currentEnvelope, setCurrentEnvelope] = useState<RedEnvelopeTransactionItem>()
  const [loading, setLoading] = useState(false)
  const [txList, setTxList] = useState<RedEnvelopeTransactionItem[]>([])

  const getReceiveLogs = async () =>{
    setLoading(true);
    const [logs, err] = await APIGetRedEnvelopeTransactions();
    setLoading(false);
    if(err) return;
    if(logs && logs.length){
      setTxList(logs);
    }
  }

  useEffect(()=>{
    if(show){
      getReceiveLogs().then();
    }
  }, [show])

  const openDetail = (item:RedEnvelopeTransactionItem) => {
    setCurrentEnvelope(item);
    setShowEnvelopeDetail(true)
  }

  const Item = ({item}: {item:RedEnvelopeTransactionItem}) => {

    function buildNum(num:number) {
      if (num > 0) { return `+${formatNumberUnit(scaledDow2w(num))}` }
      return <div className={styles.envelope_record_item_right_num} onClick={()=>openDetail(item)}>
        {formatNumberUnit(scaledDow2w(num))}
        <img src={arrowWhite} alt=""/>
      </div>
    }

    return <div className={styles.envelope_record_item}>
      {/*<div className={styles.envelope_record_item_left}></div>*/}
      <div className={styles.envelope_record_item_center}>
        <div className={styles.envelope_record_item_center_top}>{item.title}</div>
        <div className={styles.envelope_record_item_center_bot}>{item.time}</div>
      </div>
      <div className={styles.envelope_record_item_right}>{buildNum(item.balance ?? 0)}</div>
    </div>
  }



  return <div className={styles.envelope_record_wrap}>
    <DModal
      visible={show}
      title='Record'
      height="95%"
      cancel={cancel}
      content={<div className={styles.envelope_record_list}>
        { txList.map(it => <Item item={it}/>) }

        {
          txList.length <= 0 && !loading && <DEmpty></DEmpty>
        }

        {
            loading && <div style={{
              width:"100%",
              height:"100%",
              display:"flex",
              alignItems:"center",
              justifyContent:"center"
            }}>
              <LoadingOutlined></LoadingOutlined>
            </div>
        }
      </div>}
    />
    <DModal
      visible={showEnvelopeDetail}
      title='Red Packet Details'
      height="95%"
      titleStyle={{
        fontSize:"20px"
      }}
      cancel={() => setShowEnvelopeDetail(false)}
      content={<EnvelopDetail currentEnvelope={currentEnvelope} />}
    />
  </div>
}

function EnvelopDetail({currentEnvelope}:{currentEnvelope?:RedEnvelopeTransactionItem}) {
  const [link, setLink] = useState("--")

  const [logs, setLogs] = useState<RedEnvelopeClaimLogItem[]>([]);
  const [redEnvelopeDetails, setRedEnvelopeDetails] = useState<RedEnvelopeInfo>();

  useEffect(() => {
    if(currentEnvelope){
      setLink(buildRedEnvelopeLink(currentEnvelope.rId ?? '_', LinkBuildType.RedEnvelope));
      getEnvelopeLogs(currentEnvelope.rId ?? '').then()
    }
  }, [currentEnvelope]);


  const getEnvelopeLogs = async (id:string) =>{
    const [redEnvelopeDetails, infoErr] = await APIGetRedEnvelopeInfo([id]);
    if(infoErr) return;
    setRedEnvelopeDetails(redEnvelopeDetails[0]);
    const [logs, err] = await APIGetRedEnvelopeClaimLog(id)
    if(err) return;
    setLogs(logs);
  }
  async function handleCopy() {
    const ret = copy(shareLinkPre + link)
    if (ret) {
      await message.success('Copy Link: Successful')
      return
    }
    message.error('Copy Link: Failed')
  }

  function handleShare() {
    const shareLink = `https://t.me/share/url?url=${encodeURIComponent(shareLinkPre) + link}`
    TgLink(shareLink, 'tg')
  }

  const getContext = <div>
    <div className={styles.envelope_card_title}>Share the exclusive link of the red envelope to your friends to receive!</div>
    <div className={styles.envelope_card_card}>{link}</div>
    <div className={styles.envelope_btn_wrap}>
      <div><DBtn
        title='Copy'
        onclick={handleCopy}
        titleStyle={{
          textShadow: "-1px -1px 0 #0F7481, 1px -1px 0 #0F7481, -1px 1px 0 #0F7481, 1px 1px 0 #0F7481, 0px 2px 0px #0F6671",
        }}
        style={{
          width: '148px',
          height: '44px',
          background: "linear-gradient( 180deg, #38ABFF 0%, rgba(19,222,243,0.82) 100%)",
          boxShadow: "0px 6px 4px 0px rgba(84,102,109,0.67), 0px 3px 0px 0px #0F6671, inset 0px 0px 12px 0px #50FFF9"
        }} /></div>
      <div><DBtn title='Share' style={{width: '148px', height: '44px'}} onclick={handleShare} /></div>
    </div>
  </div>

  const Item = ({item}: {item:RedEnvelopeClaimLogItem}) => {
    return <div className={styles.envelope_detail_item}>
      {/*<div className={styles.envelope_detail_left}></div>*/}
      <div className={styles.envelope_detail_center}>{item.username}</div>
      <div className={styles.envelope_detail_right}>+{scaledDow2w(parseInt(redEnvelopeDetails?.coinAmount ?? "0"))}</div>
    </div>
  }

  return <div className={classnames([styles.envelope_detail_wrap, styles.envelope_public])}>
    <DCard context={getContext} />
    <div className={styles.envelope_detail_title}>Claim Details ({logs.length}/{redEnvelopeDetails?.total})</div>
    <div className={styles.envelope_detail_cont}>
      {logs.map(it => <Item item={it} />) }
      {
        logs.length <= 0 && <DEmpty></DEmpty>
      }
    </div>
  </div>
}
