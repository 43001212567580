import {AloneUserSpace, LocalStorage, MemoryStorage, UserSpace} from "./3rdparty/ts-baselib";
import {WSSUrl} from "./config";

let userSpace: UserSpace = new AloneUserSpace(new LocalStorage(), WSSUrl);

export function setUs(us:UserSpace) {
  userSpace = us
  // 注册推送

}

export function getUs():UserSpace {
  return userSpace;
}