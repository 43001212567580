import {ClassArray} from "../3rdparty/ts-json";
import {Send} from "./api";
import {message} from "antd";
import {GetUserInfo} from "./GetUserInfo";

class GetInviteListRequest {}
class GetInviteListResponse {
  errMsg:string = ""
  items: ClassArray<InviteListItem> = new ClassArray<InviteListItem>(InviteListItem)
}
export class InviteListItem {
  Id: string = ""
  Inviter:string = ""
  Uid: string = ""
  Time:number = 0
  Name:string = ''
  Energy:number = 0
}

// 邀请列表
export async function GetInviteList() {
  const [ret, err] = await Send("/API/GetInviteList", {}, GetInviteListResponse)
  if (err || ret.errMsg.length > 0) {
    return []
  }
  if (ret.items.length <= 0) return []
  const infos = await GetUserInfo(ret.items.map(it => it.Uid))
  ret.items.forEach(it => {
    const obj = infos.find(ele => ele.Uid === it.Uid)
    if (obj) { it.Name = obj.FirstName + obj.LastName }
  })
  return ret.items
}