import styles from "./index.module.scss"
import {CSSProperties} from "react";
import classnames from "classnames";
import {LoadingOutlined} from "@ant-design/icons";

interface Props {
  title: string | JSX.Element
  titleStyle?: CSSProperties & {WebkitTextStroke?: string, textStroke?: string}
  onclick?:() => void
  round?:string // 圆角大小
  style?:CSSProperties
  disabled?:boolean,
  loading?:boolean
}

export function DBtn(props: Props) {
  return <div
    className={classnames([styles.d_btn, props.disabled && styles.d_btn_disable])}
    onClick={() => !props.disabled && props.onclick && props.onclick()}
    style={{borderRadius: props.round ? props.round : '26px', ...props.style}}
  >
    <div className={styles.d_btn_dian1}></div>
    <div className={styles.d_btn_dian2}></div>
    <div className={styles.d_btn_border} style={props.titleStyle}>
      {
        props.loading && <LoadingOutlined style={{marginRight:"5px"}} />
      }
      {props.title}
    </div>
  </div>
}