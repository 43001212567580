import {PostJson} from "../3rdparty/ts-baselib";
import {getUs} from "../SoexHelper";
import {RestoreTheLatest} from "../3rdparty/ts-baselib/src/api/loginapi";
import {Me} from "../3rdparty/ts-baselib/src/userspace";

export async function Send<T extends object>(uri:string, req:object, resType:{new(...args:any[]):T}, headers:Map<string, string> = new Map()): Promise<[T, Error|null]> {

  if (getUs().getUid() === Me.empty) {
    await RestoreTheLatest()
  }

  const net = getUs().nf.get();

  const [ret, err] = await PostJson(uri, req, resType, net, headers);
  if (err) {
    console.warn("Send err", err);
    return [new resType(), new Error(err.message)];
  }

  return [ret, null];
}