// import Coin1 from "../../../../images/coin1.png"
import Coin1 from "../../../../assets/images/home/imomo/imomo-coin.png"
import Icon1 from "../../../../images/icon1.png"
// import coinPepe from "../../../../images/coin_pepe.png"
import coinPepe from "../../../../assets/images/home/imomo/imomo-coin.png"
import energyIcon from "../../../../images/energy.png"
import arrowRight from "../../../../images/arrow_right.png"
import refuseIcon from "../../../../images/refuse.png"
import styles from "./index.module.scss"
import React, {useEffect, useRef, useState} from "react";
import {DCard} from "../../../../components/DCard";
import {DBtn} from "../../../../components/DBtn";
import {Input, message} from "antd";
import {GetWithdrawList, WithdrawListItem} from "../../../../api/GetWithdrawList";
import {GetTask20List, Task20Document} from "../../../../api/GetTask20List";
import {formatNumberUnit, getEnergy, getLoginUserInfo, scaledDow2w, TgLink} from "../../../../utils/utils";
import {ChainList} from "../../../../config";
import {Withdraw, WithdrawRequest} from "../../../../api/Withdraw";
import { v4 as uuidv4 } from 'uuid';
import {CheckWithdraw} from "../../../../api/CheckWithdraw";
import DEmpty from "../../../../components/DEmpty";
import {GetConfig} from "../../../../api/GetConfig";

enum CompType {
  SELECT = 'select', // 选择
  RECORD = 'record', // 记录
  EXTRACT = 'extrace' // 提取
}



function SelectModule({click, score, energy}: {click:(type:CompType, item?: Task20Document) => void, score:number, energy:number}) {

  const [list, setList] = useState<Task20Document[]>([])

  useEffect(() => {
    getData().then()
  }, [])

  async function getData() {
    const docs = await GetTask20List()
    await GetConfig()
    const info = getLoginUserInfo();
    docs.forEach(item=>{
      item.disabled = info.withdrawLog.find((it: { Id: number })=>it.Id === item.Id)?.isWithdraw ?? false
    })
    const newDOcs = docs.sort((a, b) => {
      getEnergy(a.Condition, 'energy')
      return getEnergy(a.Condition, 'energy') - getEnergy(b.Condition, 'energy')
    })
    setList(newDOcs)
  }


  const GetContext = ({item}: {item: Task20Document}) => {
    return <div className={styles.select_module_card_con}>
      <div className={styles.select_module_card_con_top}>
        <div className={styles.select_module_card_con_top_1}>Maximum withdraw</div>
        <div className={styles.select_module_card_con_top_2}>
          <img src={coinPepe} alt=""/>
          <div>{scaledDow2w(item.Max)} momo</div>
        </div>
      </div>
      <div className={styles.select_module_card_con_bot}>
        {
          item.Status === 0 ? <div className={styles.refresh_tips}>
            Coming soon
          </div> : null
        }
        <DBtn
            onclick={() => click(CompType.EXTRACT, item)}
            disabled={item.disabled || item.Status === 0}
          title={<div className={styles.select_module_card_con_bot_con}>
            <img src={energyIcon} alt=""/>
            <div className={styles.select_module_card_con_x}>x</div>
            <div>{getEnergy(item.Condition, 'energy')}</div>
          </div>} />
      </div>
    </div>
  }

  return <div className={styles.select_module_wrap}>
    <div className={styles.select_module_top}>
      <div className={styles.select_module_wrap_line}></div>
      <div className={styles.select_module_top_item}>
        <div className={styles.select_module_top_item_con}>
          <img src={Coin1} className={styles.select_module_top_item_icon} alt=""/>
          <div>Current quantity</div>
          <div className={styles.select_module_top_item_val}>{scaledDow2w(score)}</div>
        </div>
      </div>
      <div className={styles.select_module_wrap_line}></div>
      <div className={styles.select_module_top_item}>
        <div className={styles.select_module_top_item_con}>
          <img src={Icon1} className={styles.select_module_top_item_icon} alt=""/>
          <div>Current quantity</div>
          <div className={styles.select_module_top_item_val}>{energy}</div>
        </div>
      </div>
    </div>
    <div className={styles.select_module_center}>
      {
        list.map(it => {
          return <div key={it.Id}>
            <div className={styles.select_module_wrap_line}></div>
            <DCard context={<GetContext item={it} />} />
          </div>
        })
      }

    </div>
    <div className={styles.select_module_bot} onClick={() => click(CompType.RECORD)}>Withdraw records</div>
  </div>
}
function ExtractModule({item, score, success, name}:{item:Task20Document, score:number,success:() => void, name:string}) {
  const [height, setHeight] = useState('0')
  const [nets, setNets] = useState<{chainName:string, chainId:string, blockExplorerUrls:string}[]>([])
  const [activeChainId, setActiveChainId] = useState('')
  const [extractAddress, setExtractAddress] = useState("")
  const loading = useRef(false)

  useEffect(() => {
    setNets(ChainList)
    setActiveChainId(ChainList[0].chainId)
  }, [])

  function handleClick(e:React.MouseEvent<HTMLDivElement>, key:any) {
    // e.stopPropagation()
    setActiveChainId(key.chainId)
  }
  function getName(chainId:string) {
    const s = ChainList.find(it => it.chainId === chainId)
    if (!s) return ""
    return s.chainName
  }

  const networkCell = <div className={styles.extract_module_net} onClick={() => setHeight(height === '150px' ? '0' : '150px')}>
    <div>{getName(activeChainId)}</div>
    <img src={arrowRight} alt="" style={{transform: height === '150px' ? 'rotate(90deg)' : 'rotate(0deg)'}}/>
    <div className={styles.extract_module_net_items} style={{maxHeight: height}}>
      { nets.map(it => <div key={it.chainId} className={styles.extract_module_net_item} onClick={(e) => handleClick(e, it)}>{it.chainName}</div> ) }
    </div>
  </div>

  function isValidEthereumAddress(address:string) {
    // Ethereum address must be a string of 42 characters starting with '0x' followed by 40 hexadecimal characters
    if (typeof address !== 'string' || address.length !== 42 || !address.match(/^0x[a-fA-F0-9]{40}$/)) {
      return false;
    }
    return true;
  }

  async function submit() {
    if(!isValidEthereumAddress(extractAddress)){
      return message.info("Please submit the correct Ethereum address!")
    }
    if (loading.current) return
    loading.current = true
    const request = new WithdrawRequest()
    request.address = extractAddress
    request.chainID = activeChainId
    request.id = uuidv4().replace(/-/g, "")
    request.amount = item.Max > score ? score : item.Max
    request.task20ID = item.Id
    const timer = setTimeout(() => {
      loading.current = false
      success()
    }, 2000)
    const err = await Withdraw(request)
    clearInterval(timer)
    loading.current = false
    if (err) return
    success()
  }

  return <div className={styles.extract_module_wrap}>
    <div className={styles.extract_module_top}>
      <div className={styles.extract_module_user}>
        <div className={styles.extract_module_user_dear}>Dear</div>
        <div className={styles.extract_module_user_name}>{name}</div>
      </div>
      <div className={styles.extract_module_text}>Please select withdrawal network</div>
      <div><DCard context={networkCell} /></div>
      <div className={styles.extract_module_text}>Please enter the withdrawal address</div>
      <div>
        <Input.TextArea className={extractAddress.length <= 0 ? styles.select_input_none : styles.select_input} placeholder='Input address...' value={extractAddress} onChange={(e) => setExtractAddress(e.target.value)}/>
      </div>
      <div className={styles.select_module_wrap_line}></div>
      <div className={styles.select_total_cell}>
        <div className={styles.select_total_cell_left}>Maximum account withdrawal</div>
        <div className={styles.select_total_cell_right}>{formatNumberUnit(scaledDow2w(score))} momo</div>
      </div>
      <div className={styles.select_total_cell}>
        <div className={styles.select_total_cell_left}>Maximum fuel pack withdrawal</div>
        <div className={styles.select_total_cell_right}>{formatNumberUnit(scaledDow2w(item.Max))} momo</div>
      </div>
      <div className={styles.select_total_cell}>
        <div className={styles.select_total_cell_left}>Consume fuel</div>
        <div className={styles.select_total_cell_right}>{getEnergy(item.Condition, 'energy')}</div>
      </div>
    </div>
    <div className={styles.select_bot_btn}>
      <DBtn title='Withdraw' disabled={extractAddress.length <= 0 || activeChainId.length <= 0 || score <= 0} onclick={submit} />
    </div>
  </div>
}
function ExtractRecord() {
  enum Status {
    SUCCESS = 1,
    FAIL = 0,
    WAIT = 2
  }
  const [list, setList] = useState<WithdrawListItem[]>([])

  useEffect(() => {
    getData().then()
  }, [])

  async function getData() {
    const docs = await GetWithdrawList()
    setList(docs)
  }

  function getStatus(s: Status) {
    if (s === Status.SUCCESS) return <div className={styles.record_status_success}>Successful</div>
  }
  function buildAddress(str:string) {
    if (!str || str.length <= 8) return str
    const start = str.substring(0, 4)
    const end = str.substring(str.length - 4)
    return `${start}...${end}`
  }

  async function handleRefuse(it: WithdrawListItem) {
    const ret = await CheckWithdraw(it.Id)
    if (ret) {
      return message.warning(ret)
    }
    message.success('Successful')
    await getData()
  }

  return <div className={styles.record_module_wrap}>
    <div>
      {
        list.map((it, index) => <div key={index}>
          <DCard context={<div className={styles.record_item}>
            <div className={styles.record_item_top}>
              <div className={styles.record_item_top_left}>Withdraw: {scaledDow2w(it.Amount)} momo</div>
              {/*<div className={styles.record_item_top_right}>{getStatus(it.status)}</div>*/}
              <img className={styles.record_item_refuse} src={refuseIcon} onClick={() => handleRefuse(it)} />
            </div>
            <div className={styles.record_item_bot}>
              <div className={styles.record_item_bot_left}>Receiving address:<span>{buildAddress(it.ToAddress)}</span></div>
              <div className={styles.record_item_wrap_btn}>
                <DBtn
                  title='View'
                  onclick={() => TgLink(it.ScanURL, 'web')}
                  titleStyle={{
                    fontWeight: 900,
                    fontSize: "14px",
                    color: "#FFFFFF",
                    textShadow: "-1px -1px 0 #0F7481, 1px -1px 0 #0F7481, -1px 1px 0 #0F7481, 1px 1px 0 #0F7481, 0px 2px 0px #0F6671",
                  }}
                  style={{
                    width: '82px',
                    height: '32px',
                    background: "linear-gradient( 180deg, #38ABFF 0%, rgba(19,222,243,0.82) 100%)",
                    boxShadow: "0px 6px 4px 0px rgba(84,102,109,0.67), 0px 3px 0px 0px #0F6671, inset 0px 0px 12px 0px #50FFF9"
                  }} />
              </div>

            </div>
          </div>}/>
          <div className={styles.select_module_wrap_line}></div>
        </div>)
      }
      {
        !list || list.length <= 0  ? <div style={{
          marginTop:"80px"
        }}>
          <DEmpty></DEmpty>
        </div> : null
      }
    </div>
  </div>
}

export function ExtractComp({score, energy, confirm, name}: {score:number, energy:number, confirm:() => void, name:string}) {

  const [type, setType] = useState(CompType.SELECT)
  const [currentTask20Item, setCurrentTask20Item] = useState<Task20Document>(new Task20Document())

  function handleToggle(e: CompType, item?: Task20Document) {
    setType(e)
    if (e !== CompType.EXTRACT) return
    if (item) setCurrentTask20Item(item)

  }

  const buildComp = (t: CompType) => {
    if (t === CompType.EXTRACT) { return <ExtractModule name={name} item={currentTask20Item} score={score} success={confirm} /> }
    if (t === CompType.RECORD) { return <ExtractRecord /> }
    return <SelectModule score={score} energy={energy} click={handleToggle} />
  }
  return <div className={styles.extract_comp_wrap}>
    { buildComp(type) }
  </div>
}
