import {ClassArray} from "../3rdparty/ts-json";
import {Send} from "./api";
import {getLoginUserInfo} from "../utils/utils";

class GetUserInfoRequest {
  uids:string[] = []
}

class GetUserInfoResponse {
  errMsg:string = ""
  items:ClassArray<GetUserInfoItem> = new ClassArray<GetUserInfoItem>(GetUserInfoItem)
}

export class GetUserInfoItem {
  Uid:string = ""
  FirstName:string = ""
  LastName:string = ""
  Username:string = ""
  LanguageCode:string = ""
  Score:number = 0
  Energy:number = 0
  ERC20:number = 0
  Inviter:string = ""
  Invite:number = 0
  InviteTime:number = 0
  InviteEnergy:number = 0
  CreateTime:number = 0
  LoginTime:number = 0
  Ids:string[] = []
  CaptchaVerify:number = 0
  ChatID: number = 0
}

// 获取用户信息
export async function GetUserInfo(uids:string[]):Promise<GetUserInfoItem[]> {
  const request = new GetUserInfoRequest()
  request.uids = uids
  const [ret, err] = await Send("/API/GetUserInfo", request, GetUserInfoResponse)
  if (err || ret.errMsg.length > 0) { return [] }
  return ret.items
}
