import {Send} from "./api";

class CheckWithdrawRequest {
  id: string = ""
}
class CheckWithdrawResponse {
  errMsg: string = ""
}

export async function CheckWithdraw(id:string) {
  const request = new CheckWithdrawRequest()
  request.id = id
  const [ret, err] = await Send("/API/CheckWithdraw", request, CheckWithdrawResponse)
  if (err || ret.errMsg.length > 0) {
    return ret.errMsg
  }
  return null
}
