import {Send} from "./api";
import {message} from "antd";

class CheckFollowTwitterRequest {}
class CheckFollowTwitterResponse {
  errMsg:string = ""
}

export async function CheckFollowTwitter() {
  const [ret, err] = await Send("/API/CheckFollowTwitter", {}, CheckFollowTwitterResponse)
  if (err || ret.errMsg.length > 0) {
    return err || ret.errMsg
  }
  return null
}