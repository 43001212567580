import {Send} from "./api";

export enum CreateRedEnvelopeCodeRes{
    codeSuccess = 0,
    codeInsufficientBalance =1
}

class CreateRedEnvelopeRequest{
    numberOfPackets?:number   // 红包数量
    amountPerPacket?:number // 红包里的PEPE个数
}

class CreateRedEnvelopeResponse{
    code?:CreateRedEnvelopeCodeRes
    errMsg?:string
    rid?:string
}

// 创建红包
export const APICreateRedEnvelope = async (numberOfPackets:number, amountPerPacket:number):Promise<[CreateRedEnvelopeResponse, Error | null]> =>{
    const request = new CreateRedEnvelopeRequest();
    request.numberOfPackets = numberOfPackets;
    request.amountPerPacket = amountPerPacket;
    const [ret, err] = await Send("/API/CreateRedEnvelope", request, CreateRedEnvelopeResponse)
    if(err){
        return [{}, err]
    }

    if(ret.errMsg){
        return [{}, new Error(ret.errMsg)]
    }

    return [ret, null]
}