import classnames from "classnames";
import styles from './styles.module.scss'
import lockIcon from '../../../../assets/images/home/lock-icon.png'
import timeIcon from '../../../../assets/images/home/time-icon.png'
import clockIcon from '../../../../assets/images/home/clock-icon.png'
// import tokenIcon from '../../../../assets/images/home/token-icon.png'
import tokenIcon from '../../../../assets/images/home/imomo/imomo-coin.png'
import textImg from '../../../../assets/images/home/text_icon.png'
import {SoexModule} from "../../index";
import {GetTaskList, GetTaskListResponseItem} from "../../../../api/GetTaskList";
import {useEffect, useRef, useState} from "react";
import {Claim} from "../../../../api/Claim";
import {getSeconds, scaledDow2w} from "../../../../utils/utils";
import {getUs} from "../../../../SoexHelper";
import {ClaimSuccess, TimeChangedEvent} from "../../../../utils/NC";
import {runAfter} from "../../../../utils/Async";

interface IProps {
  time?: string
  second?: string | number
  level?: number
  count?: string | number
  className?: string
  click?: (type: SoexModule, it: GetTaskListResponseItem) => void
  item: GetTaskListResponseItem
  recive?:(item: GetTaskListResponseItem) => void
}


const Card = (props: IProps) => {
  const { level = 1 } = props

  const [time, setTime] = useState(0)
  const timeRef = useRef(0)
  const [coinNum, setCoinNum] = useState(0)
  const loading = useRef(false)
  const [start, setStart] = useState(false);

  function getBorder(lev: number) {
    if ((lev - 1) % 3 === 0) {
      return styles.border1
    }
    if ((lev - 1) % 3 === 1) {
      return styles.border2
    }
    if ((lev - 1) % 3 === 2) {
      return styles.border3
    }
    return ''
  }

  function getTag(lev: number) {
    if ((lev - 1) % 3 === 0) {
      return styles.level1
    }
    if ((lev - 1) % 3 === 1) {
      return styles.level2
    }
    if ((lev - 1) % 3 === 2) {
      return styles.level3
    }
    return ''
  }

  useEffect(()=>{
    setTimeout(()=>{
      setStart(true)
    }, 3300)
  }, [])

  useEffect(() => {
    getUs().nc.addEvent(TimeChangedEvent, async () => {
      calcTimer()
    })
  }, [])

  function calcTimer() {
    const {max, rate, startTime} = props.item
    const totalTime = parseInt((max / rate).toString())
    const t = startTime === 0 ? totalTime : totalTime - getSeconds(Date.now() - startTime * 1000)
    setTime(t <= 0 ? 0 : t)
    if (t <= 0) {
      setCoinNum(startTime > 0 ? max : 0)
      return
    }
    timeRef.current = t
    const num = (totalTime - t) * rate
    // 挖取数量
    setCoinNum(num)
    if (num >= max) {
      setTime(0)
      timeRef.current = 0
      return
    }

    const count = timeRef.current - 1
    timeRef.current = count
    setTime(count)
    setCoinNum(prev => {
      let n = prev + rate
      if (n >= max) { n = max }
      return n
    })
  }

  function secondsToHMS(seconds: number) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;

    const hh = h.toString().padStart(2, '0');
    const mm = m.toString().padStart(2, '0');
    const ss = s.toString().padStart(2, '0');
    return `${hh}:${mm}:${ss}`;
  }

  async function ReClaimFn(taskID:number) {
    const tasks = await GetTaskList()
    const obj = tasks.find(it => it.taskID === taskID)
    if (!obj) return false
    const err = await Claim(taskID, obj.startTime, getSeconds(Date.now()))
    if (err) return false
    return true
  }

  async function handleRecive() {
    const {taskID, startTime} = props.item
    getCoinAnimation(5)

    if (loading.current) return;
    loading.current = true;
    const err = await Claim(taskID, startTime, getSeconds(Date.now()))
    if (err) {
      if (err.includes("oldTime is invalid")) {
        const ret = await ReClaimFn(taskID)
      }
    }
    props.recive && props.recive(props.item);
    setTimeout(() => { loading.current = false; }, 2000)
  }

  const getCoinAnimation = (coinNum = 10) => {
    if(coinNum > 7) coinNum = 7
    const startPos = document.getElementById(`claim-btn-${props.item.taskID}`);
    const startPosRect = startPos!.getBoundingClientRect();
    const startX = startPosRect.left + startPosRect.width / 2;
    const startY = startPosRect.top + startPosRect.height / 2;

    const target = document.getElementById('coinBalance');
    const targetRect = target!.getBoundingClientRect();
    const endX = targetRect.left + 0.5;
    const endY = targetRect.top + (targetRect.height - 35) / 2;

    const deltaX = endX - startX;
    const deltaY = endY - startY;
    const animationName = `coinAnimation-${Date.now()}`;
    const keyframes = `
        @keyframes ${animationName} {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }
            100% {
                transform: translate(${deltaX}px, ${deltaY}px);
                opacity: 0.9;
            }
        }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = keyframes;
    document.head.appendChild(styleSheet);

    for (let i = 0; i < coinNum; i++) {
      const coin = document.createElement('div');
      coin.className = 'coin_icon';
      document.body.appendChild(coin);
      coin.style.left = `${startX}px`;
      coin.style.top = `${startY}px`;
      coin.style.animation = `${animationName} 1s cubic-bezier(0.25, 1, 0.5, 1) forwards`;
      coin.style.animationDelay = `${i * 0.1}s`; // Delay for a more staggered effect
      coin.style.opacity = "0";
      coin.addEventListener('animationend', () => {
        coin.classList.add('hidden');
        setTimeout(() => {
          document.body.removeChild(coin);
        }, 500);
      });
    }

    setTimeout(() => {
      document.head.removeChild(styleSheet);
    }, 1000 + coinNum * 100);
  };

  const getImage = () => {
    if(start)  return <img className={styles.img_2} src={textImg}/>
    return null
  }

  const getImageBg = () =>{
    return `https://imomo.s3.ap-southeast-1.amazonaws.com/imomo-tg/${level}.JPG`
  }

  return (
      <div className={classnames([styles.card, getBorder(level)])}>
        <img className={`${styles.img_bg} ${props.item.lock !== 0 ? "gray_filter" : ""}`} src={getImageBg()} />
        <div className={classnames([styles.level, getTag(level)])}>
          <div>Level{level}</div>
        </div>
        <div className={styles.options}>
          {
              props.item.lock === 0 && <div className={styles.time}>
            <img src={timeIcon} alt="timeIcon"/>
            <div>{secondsToHMS(time)}</div>
          </div>
        }
        { props.item.lock === 0 && <div className={styles.empty}></div>}
          <div className={styles.clock}>
            <img src={clockIcon} alt="clockIcon"/>
            <div>+{scaledDow2w(props.item.rate)}/S</div>
          </div>
        </div>

        <div>
          {
            props.item.lock === 1 ? (
                <div id={`claim-btn-${props.item.taskID}`} className={styles.disabled} onClick={() => props.click && props.click(SoexModule.UNLOCK, props.item)}>
                  <div>
                    <img src={lockIcon} className={styles.lockImg} alt="lockIcon"/>
                    <span>Revenue</span>
                  </div>
                </div>
            ) : (
                <div id={`claim-btn-${props.item.taskID}`} className={styles.btn} onClick={handleRecive}>
                  <div className={styles.token}>
                    <img src={tokenIcon} alt="tokenIcon"/>
                    {scaledDow2w(coinNum)}
                  </div>
                  Receive
                </div>
            )
          }
        </div>

    </div>
  )
}

export default Card
