import React, {useState, useEffect, useRef} from 'react';
import "./index.scss"
// import coinIcon from "./coin.png"
import coinIcon from  "../../../../assets/images/home/imomo/imomo-coin.png";
// import pepeIcon from "../../../../images/pepe.png";
import pepeIcon from "../../../../assets/images/home/imomo/imomo-logo.png";
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const CoinAnimation = () => {
  const bagRect = useRef<DOMRect|null>()
  const domRef = useRef<HTMLElement|null>()
  useEffect(() => {
    const interval = setInterval(() => {
      createCoin();
    }, 100);
    return () => clearInterval(interval);
  }, []); // 只在组件加载时运行一次

  function createCoin() {
    if (!bagRect.current) {
      const dom = document.getElementById('bag');
      if (!dom) return
      bagRect.current = dom.getBoundingClientRect();
      domRef.current = dom
    }
    const coin = document.createElement('img');
    coin.classList.add('coin');

    const size = Math.floor(Math.random() * 40) + 20;
    coin.style.width = `${size}px`;
    coin.style.height = `${size}px`;
    coin.src = coinIcon

    const direction = Math.random() > 0.5 ? 1 : -1;
    const startX = bagRect.current.width / 2;
    const startY = 0;
    const endX = (Math.random() * 200 + 10) * direction;
    const endY = Math.random() * 200 + 20;

    coin.style.left = `${startX}px`;
    coin.style.top = `${startY}px`;

    domRef.current?.appendChild(coin);

    gsap.timeline().to(coin, {
        duration: 2,
        motionPath: {
          path: [
            { x: 0, y: 0 },
            { x: endX, y: startY - 100 },
            { x: endX, y: endY }
          ],
          autoRotate: true
        },
        opacity: 1,
        ease: "power1.inOut",
        onComplete: () => {
          setTimeout(() => {
            coin.remove();
          }, 3000);
        }
      });
  }

  return (
    <div className="coin_container">
      <div className="bag" id="bag">
        <div className={"coin_wrapper"}>
          <img src={pepeIcon} className="pepeIcon" style={{ width: '100%', height: '100%'}} />
        </div>
      </div>
    </div>
  );
};

export default CoinAnimation;
