import {getUs} from "../SoexHelper";
import {ActionType} from "../pages/Home/components/Tasks";
import BigNumber from "bignumber.js";
import {scaleNum} from "../config";

export function formatNumberUnit(num: number): string {
  const absNum = Math.abs(num);
  let formattedNum = '';

  if (absNum >= 1e9) {
    formattedNum = (absNum / 1e9).toFixed(2) + 'B';
  } else if (absNum >= 1e6) {
    formattedNum = (absNum / 1e6).toFixed(2) + 'M';
  } else if (absNum >= 1e3) {
    formattedNum = (absNum / 1e3).toFixed(2) + 'K';
  } else {
    formattedNum = absNum.toString();
  }

  return num < 0 ? '-' + formattedNum : formattedNum;
}


// 千分位
export function formatNumberWithCommas(num:number) {
  return num.toLocaleString()
}

export async function IsLogin(uid:string) {
  if (uid !== getUs().getUid()) return false
  const token = await getUs().nf.get().getToken()
  return token.length > 0
}

export function getLoginUserInfo() {
  const info = localStorage.getItem("__soex_bot__info__")
  if (!info) return {joinTGGroup: 0, followTwitter: 0, followPepeBot: 0, shareUrl: "",   theEarlyMinerCheckIn: 0, followSoexTwitter:0, joinTGSoexCommunity:0}
  return JSON.parse(info)
}
// 根据毫秒获取秒
export function getSeconds(min:number) {
  return parseInt((min / 1000).toString())
}

// 根据key从字符串中获取对应的value值
export function getEnergy(condition:string, getKey:string):number {
  const values = condition.split(',');
  const obj:any = {};
  values.forEach(pair => {
    const [key, value] = pair.split(':');
    obj[key] = isNaN(Number(value)) ? Number(value) : Number(value);
  });
  if (obj[getKey]) { return obj[getKey] }
  return 0
}

export function TgLink(url:string, source:'tg'|'web') {
  if (source === 'web') {
    window.open(url, '_blank')
    return
  }
  const win = window as any
  const tg = win.Telegram?.WebApp;
  if (!tg) { window.open(url, '_blank')}
  tg.openTelegramLink(url)
}

export const isMobile = (): boolean => {
  let sUserAgent = window.navigator.userAgent.toLowerCase();
  let bIsIpad = sUserAgent.match(/ipad/i) !== null;
  let bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
  let bIsIphoneOsNew = sUserAgent.match(/iphone os/i) !== null && sUserAgent!.match(/iphone os/i)![0] === "iphone os";

  let bIsMidp = sUserAgent.match(/midp/i) !== null;
  let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
  let bIsUc = sUserAgent.match(/ucweb/i) !== null;
  let bIsAndroid = sUserAgent.match(/android/i) !== null;
  let bIsAndroidNew = sUserAgent.match(/android/i) !== null && sUserAgent!.match(/android/i)![0] === "android";
  let bIsCE = sUserAgent.match(/windows ce/i) !== null;
  let bIsWM = sUserAgent.match(/windows mobile/i) !== null;

  let bIsMinWin = (window.innerWidth || (document.body && document.body.clientWidth)) < 820;

  if (!(bIsMinWin || bIsIpad || bIsIphoneOs || bIsIphoneOsNew || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsAndroidNew || bIsCE || bIsWM)) {
    return false;
  }
  return true;
};


export const getYearMonthDayTimeStampNew = (timeStamp?: number): string => {
  if(!timeStamp) return "";
  const date = new Date(timeStamp);
  let month: string;

  switch (date.getMonth() + 1) {
    case 1:
      month = 'Jan';
      break;
    case 2:
      month = 'Feb';
      break;
    case 3:
      month = 'Mar';
      break;
    case 4:
      month = 'Apr';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'Jun';
      break;
    case 7:
      month = 'Jul';
      break;
    case 8:
      month = 'Aug';
      break;
    case 9:
      month = 'Sep';
      break;
    case 10:
      month = 'Oct';
      break;
    case 11:
      month = 'Nov';
      break;
    case 12:
      month = 'Dec';
      break;
    default:
      throw new Error('Invalid month');
  }

  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};


export const addTaskToLocal = (task:ActionType) =>{
  const taskList = localStorage.getItem("taskList");
  const waitTaskList = taskList ? JSON.parse(taskList) : [];
  waitTaskList.push(task)
  localStorage.setItem("taskList", JSON.stringify(waitTaskList));
}

export const getLocalTaskList = ():ActionType[] =>{
  const taskList = localStorage.getItem("taskList");
  const waitTaskList = taskList ? JSON.parse(taskList) as ActionType[] : [];
  return  waitTaskList
}

export const removeLocalTask = (task:ActionType) =>{
  const taskList = localStorage.getItem("taskList");
  const waitTaskList = taskList ? JSON.parse(taskList) as ActionType[] : [];
  const newList =  waitTaskList.filter(item => item !== task);
  localStorage.setItem("taskList", JSON.stringify(newList));
}

// 将币缩小20000倍
export function scaledDow2w(num: number) {
  const originalNumber = new BigNumber(num)
  // 将数字缩小 20000 倍
  return originalNumber.div(scaleNum).toNumber();
}
