import {ClassArray} from "../3rdparty/ts-json";
import {GetUserInfoItem} from "./GetUserInfo";
import {Send} from "./api";

export enum RankType {
  SCORE = 'Score',
  ENERGY = "Energy",
  ERC20 = 'ERC20',
  INVITE = "Invite"
}
class SearchRequest {
  field:RankType = RankType.SCORE
  limit:number = 0
}
class SearchResponse {
  errMsg:string = ""
  items: ClassArray<GetUserInfoItem> = new ClassArray<GetUserInfoItem>(GetUserInfoItem)
}

// 排行榜列表
export async function Search(field: RankType, limit = 100) {
  const request  = new SearchRequest()
  request.field = field
  request.limit = limit

  const [ret, err] = await Send("/API/Search", request, SearchResponse)
  if (err || ret.errMsg.length > 0) { return [] }
  return ret.items
}