import { Send } from "./api";
import {ClassArray} from "../3rdparty/ts-json";

// 请求类
class GetRedEnvelopeInfoRequest {
    rIds?: string[]; // 红包ID数组
}

// 红包信息类
export class RedEnvelopeInfo {
    id?: string;
    total?: string; // 红包总数
    coinAmount?: string; // 每个红包的代笔数量
    claimedTotal?: string; // 已经领取的红包总数
    expired?: number; // 过期时间
    userId?: string; // 谁创建的
    userName?:string = "--";
    hasOpened?:boolean = false //当前用户是否打开过这个红包
}

// 响应类
class GetRedEnvelopeInfoResponse {
    errMsg?: string;
    items?: ClassArray<RedEnvelopeInfo> = new ClassArray<RedEnvelopeInfo>(RedEnvelopeInfo);
}

// 获取红包详情
export const APIGetRedEnvelopeInfo = async (rIds: string[]): Promise<[RedEnvelopeInfo[], Error | null]> => {
    const request = new GetRedEnvelopeInfoRequest();
    request.rIds = rIds;

    const [ret, err] = await Send("/API/GetRedEnvelopeInfo", request, GetRedEnvelopeInfoResponse);

    if (err) {
        return [[], err];
    }

    if (ret.errMsg) {
        return [[], new Error(ret.errMsg)];
    }

    if(!ret.items || ret.items.length <= 0){
        return [[], new Error("")];
    }

    return [ret.items, null];
}
